import React, { useEffect, useState } from "react";
import { Card, Table, Space, Avatar } from "antd";
import { Dropdown } from "antd";
import { MoreOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { searchProductsAction } from "appRedux/actions/myProductsAction";
import { useHistory } from "react-router-dom";
import { isArrayNonEmpty, isValidElement, isValidString } from "util/ApiHelper";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { fetchSuccess, fetchStart } from "../../appRedux/actions";
import { logoFromString } from "../../util/helper";
import { BUSINESS_TYPE_LABEL, BUSINESS_TYPE_ID } from "../../util/Constant";
import { capitalize } from "lodash";
import EnquiryStatusTags from "../enquiries/EnquiriesStatus";
import { format } from 'date-fns';

let navigateToDetails;

const MyUsersList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userAdminData, setUserAdminData] = useState(null);
  console.log("userAdminData",userAdminData)

  const currentPage = 1;
  const dataSize = 10;

  const columns = [
    {
      title: "User Name",
      key: "User Name",
      dataIndex: "name",
      sorter: true,
      width: "25%",
      render: (text, record) => (
        <div className={"gx-flex-row gx-align-items-center"}>
          <div className={"gx-pr-2"}>
            <Avatar
              src={isValidString(record?.companyLogo) && record?.companyLogo}
              className="gx-avatar gx-pointer"
              alt=""
              style={{ height: 40, width: 40 }}
            >
              {!record?.companyLogo && logoFromString(text)}
            </Avatar>
          </div>
          <div className={"highlighted-column"}>{text}</div>
        </div>
      ),
    },
    {
      title: "Company Name",
      key: "companyName",
      render: (record) => <div>{record?.companyName}</div>,
    },
    {
      title: "Business Type",
      key: "companyName",
      render: (record) => (
        <div>
          {BUSINESS_TYPE_LABEL[BUSINESS_TYPE_ID[record?.businessTypeId]] ||
            "Unknown"}
        </div>
      ),
    },
    {
      title: "Mobile Number",
      key: "Mobile Number",
      sorter: true,
      width: "25%",
      render: (record) => <div>{record?.mobile}</div>,
    },
    {
      title: "Email",
      key: "Email",
      sorter: true,
      width: "25%",
      render: (record) => <div>{record?.email}</div>,
    },
    {
      title: "Date",
      key: "createdAt",
      render: (record) => (
        <div>
          {record?.createdAt
            ? format(new Date(record.createdAt), "MMMM dd, yyyy hh:mm a")
            : ''}
        </div>
      ),
    },
    {
      title: "Status",
      key: "Status",
      width: "25%",
      render: (record) => <EnquiryStatusTags status={record?.status} />,
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <div className="myexim-action-icon" onClick={(e) => e.stopPropagation()}>
          <Dropdown
            menu={{
              items: [
                {
                  label: "View Details",
                  key: "1",
                  icon: <EyeOutlined />,
                  onClick: () => {
                    navigateToDetails(record?.id);
                  },
                },
                {
                  label: "Edit Profile",
                  key: "2",
                  icon: <EyeOutlined />,
                  onClick: () => {
                    navigateProfileEdit(record?.id);
                  },
                },
              ],
            }}
            trigger={["click"]}
          >
            <Space>
              <MoreOutlined />
            </Space>
          </Dropdown>
        </div>
      ),
    },
  ];

  const getUsersListAPICCall = async (page, limit) => {
    const response = await axiosInstance.get(
      `${BASE_API_CONFIG.baseURL}/admin/users?&page=${page}&limit=${limit}`
    );
    setUserAdminData(response?.data?.data);
    dispatch(fetchSuccess());
  };

  useEffect(() => {
    dispatch(fetchStart());
    getUsersListAPICCall(currentPage, dataSize);
  }, [dispatch]);
  const [searchText, setSearchText] = useState(null);

  const searchProductList = useSelector(
    (state) => state.myProducts.searchProducts
  );

  const onShowSizeChange = (current, pageSize) => {
    getUsersListAPICCall(current, pageSize);
  };

  const onSearch = (e) => {
    if (isValidString(e?.target?.value)) {
      dispatch(searchProductsAction(e.target.value));
      setSearchText(e.target.value);
    }
  };
  navigateToDetails = (id) => {
    if (isValidElement(id)) {
      dispatch(fetchStart());
      history.push(`users/details?id=${id}`);
    }
  };

  const navigateProfileEdit = (id) => {
    if (isValidElement(id)) {
      dispatch(fetchStart());
      history.push(`/users/editProfile?id=${id}`);
    }
  };

  const tableData =
    isValidString(searchText) && searchText.length > 0
      ? isArrayNonEmpty(searchProductList?.rows)
        ? searchProductList
        : []
      : userAdminData;

  return (
    <Card
      title={
        <div style={{ display: "flex" }}>
          <div className="myexim-table-title">My Users</div>
        </div>
      }
    >
      <div>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          onRow={(record) => {
            return {
              onClick: (e) => {
                if (
                  !e.target.classList.contains(
                    "ant-dropdown-menu-title-content"
                  )
                ) {
                  navigateToDetails(record?.id);
                }
              },
            };
          }}
          className="gx-table-responsive my-product-table myexim-table"
          total={tableData?.count}
          columns={columns}
          dataSource={tableData?.rows}
          pagination={{
            position: ["bottomRight"],
            showSizeChanger: true,
            pageSize: 10,
            total: tableData?.count,
            onChange: onShowSizeChange,
          }}
        />
      </div>
    </Card>
  );
};

export default MyUsersList;

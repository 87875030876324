import { all, call, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../util/Api";
import { showAlert } from "../../util/helper";
import { MY_EXIM_API_POINTS } from "../services/pointsNetwork";
import { FETCH_START, FETCH_SUCCESS } from "../types/commonTypes";
import { POINTS_TYPES } from "../types/pointsTypes";

function* makeUpdatedPointsCall(action) {
    try{
        yield put({ type: FETCH_START });
        const response = yield apiCall(MY_EXIM_API_POINTS.getUpdatedPoints, action.payload.uuid.uuid);
        yield put ({
            type: POINTS_TYPES.GET_POINTS_SUCCESS,
            payload:response.data.data.points || 0,
        });
    } catch(error) {
        yield call(showAlert, "error?.response?.data.message" || 'Failed to fetch points');
    } finally {
        yield put ({ type: FETCH_SUCCESS});
    }
}

export function* watchGetUpdatedPoints() {
    yield takeLatest(POINTS_TYPES.GET_POINTS,makeUpdatedPointsCall)
}

export default function* pointSaga() {
    yield all([watchGetUpdatedPoints()]);
}
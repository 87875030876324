// ProfileAvatar.jsx
import React from "react";
import { Avatar, Button } from "antd";
import { logoFromString } from "../../util/helper";

export const ProfileAvatar = ({ profileImage, fileUpload, removeProfile, authUser }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 10}} className="gx-mt-4">
      <Avatar
        src={profileImage && profileImage}
        size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
        className="gx-avatar gx-pointer"
        alt=""
        style={{ height: 60, width: 60 }}
      >
        {!profileImage && logoFromString(authUser?.name)}
      </Avatar>
      <input
        type="file"
        style={{ display: "none" }}
        id={"logo-upload"}
        onChange={fileUpload}
      />
      <div>
        <Button
          size="small"
          style={{ verticalAlign: "middle", margin: 10 }}
          onClick={() => {
            let fileinput = document.getElementById("logo-upload");
            fileinput.click();
          }}
        >
          {profileImage ? "Change Logo" : "Add Logo"}
        </Button>
      </div>
      <div>
        {profileImage && (
          <Button
            type="text"
            size="small"
            style={{ verticalAlign: "middle", margin: 10 }}
            onClick={removeProfile}
          >
            Remove
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProfileAvatar;

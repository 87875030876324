import { BUSINESS_TYPE } from './../../util/Constant';

export const ProfileDocuments ={
    [BUSINESS_TYPE.BUYER]:[{
        key:'CC',
        desc: 'Company Certificate',
    }],
    [BUSINESS_TYPE.EXPORTER]:[{
        key:'GST',
        desc: 'GST Certificate',
    },{
        key:'EXIM',
        desc: 'EXIM Certificate',
    }],
    [BUSINESS_TYPE.MANUFACTURER]:[{
        key:'GST',
        desc: 'GST Certificate',
    },{
        key:'EXIM',
        desc: 'EXIM Certificate',
    }],
    [BUSINESS_TYPE.MANUFACTURER_EXPORTER]:[{
        key:'GST',
        desc: 'GST Certificate',
    },{
        key:'EXIM',
        desc: 'EXIM Certificate',
    }],
    [BUSINESS_TYPE.CUSTOM_HOUSE_AGENT]:[{
        key:'GST',
        desc: 'GST Certificate',
    },{
        key:'EXIM',
        desc: 'EXIM Certificate',
    }],
    [BUSINESS_TYPE.FREIGHT_FORWARDER]:[{
        key:'GST',
        desc: 'GST Certificate',
    },{
        key:'EXIM',
        desc: 'EXIM Certificate',
    }],
    [BUSINESS_TYPE.SHIPPING_LINE]:[{
        key:'GST',
        desc: 'GST Certificate',
    },{
        key:'EXIM',
        desc: 'EXIM Certificate',
    }],
    [BUSINESS_TYPE.COMMISSION_AGENT]:[{
        key:'GST',
        desc: 'GST Certificate',
    },{
        key:'EXIM',
        desc: 'EXIM Certificate',
    }],
}


export const MapDocumentData = (attachments,documents) => {
     if(!attachments || !attachments?.length){
        return documents
     }else{
        documents?.map(doc => {
            const filteredData = attachments.find(attachment => attachment.key === doc.key);
            if(filteredData){
                doc['src'] = filteredData?.src;
                doc['originalName'] = filteredData?.originalName;
            }
            return doc
        })
        return documents;
     }
}
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Card, Pagination, Table } from "antd";
import "./MyProfile.css";
import SuccesIcon from "../../assets/images/Success_button.png.svg"
import FailureIcon from "../../assets/images/Failure_button.png.svg"
import Success from "../../assets/images/Sucess.png.png"
import Failure from "../../assets/images/failure.png.png"

import { formatDate } from "../../util/helper";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import { ItemDetailMobile } from "../../components/ItemDetailMobile/myprofile";

const PaymentHistory=({paymentData})=> {

  const { isMobile } = useGetBreakpoints(); 

  const [currentPage, setCurrentPage] = useState(1); 
  const [pageSize, setPageSize] = useState(5); 

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return paymentData.slice(startIndex, endIndex);
  }, [paymentData, currentPage, pageSize]);


  const adjustedPoints = paymentData.amount / 100;


      const columns = [
        {
            title: "TransactionDate",
            key: "transactiondate",
            dataIndex:'transactiondate',
            render: (text) => formatDate(text),
            
          },
        {
          title: "Description",
          key: "description",
          dataIndex:'description',
       
        },
        {
          title: "Currency",
          key: "currency",
          dataIndex:'currency',
        },
        {
          title: "Amount",
          key: "transactionamount",
          dataIndex:'transactionamount',
          align:"right",
        },
        {
          title: "Points",
          key: "Points",
          dataIndex:'Points',
          align:"right",
        },
        {
            title: "HostPayment ID",
            key: "hostPaymentid",
            dataIndex:'hostPaymentid',
          },
        {
          title: "Status",
          key: "status",
          dataIndex:'status',

          align:"center",
          width: 100,
          render: (text) => (
            <div className={`status ${text === "Success" ? "success" : "failed"}`}>
              <img
                src={
                  text === "Success"
                    ? SuccesIcon 
                    : FailureIcon 
                }
                alt={text}
              />
            </div>
          )
        },
    ]

    const renderMobileView = useCallback(
      () =>
        paginatedData.map((payment) => (
          <div key={payment.key} style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={payment.status === 'Success' ? Success : Failure} 
            alt="Points Logo"
            style={{ width: '74px', height: '74px' }}
          />
          <ItemDetailMobile
            key={payment.key}
            postedDate={formatDate(payment.transactiondate)}
            primaryRow={{
              text: `${payment.status === "Success" ? "+" : "-"}${payment.transactionamount/100} Points`,
              subText: (
                <span style={{ marginLeft: '40px',fontSize:'15px',
                }}>
                  {payment.transactionamount}
                </span>
              ),
            }}
            secondaryRow={{
              text: `${payment.hostPaymentid}`,
              subText: (
                <span style={{ marginLeft: '40px',fontSize:'15px', }}>
                  {payment.currency}
                </span>
              ),
            }}
            status={payment.status}
            StatusComponent={({ status }) => (
              <div className={`status ${status === 'Success' ? 'success' : 'failed'}`}>
                <img
                  src={status === 'Success' ? SuccesIcon : FailureIcon}
                  alt={status}
                />
              </div>
            )}
          />
          </div>
        )),
      [paginatedData]
    );

    const handlePageChange = (page, newPageSize) => {
      console.log('Page changed:', page, 'Page size:', newPageSize);
      setCurrentPage(page);
      if (newPageSize !== pageSize) {
        setPageSize(newPageSize);
        setCurrentPage(1);
      }
    };

    const handleNextPage = () => {
      if (currentPage < Math.ceil(paymentData.length / pageSize)) {
        handlePageChange(currentPage + 1, pageSize);
      }
    };
  
    return (
      <Card>
        <div className="myexim-table-myactivity-title">Payment History</div>
        {isMobile ? (
          
          <>
         
          <div className="mobile-payment-list" >{renderMobileView()}</div>
          
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={paymentData.length}
            onChange={handlePageChange}
            showSizeChanger={false}
            showQuickJumper={false}
            simple
            style={{ marginTop: 16, textAlign: 'center' }}
            
          />
          </>
          
        ) : (
    <Table
      rowKey={"key"}
      className="gx-table-responsive rfq-table myexim-table"
      columns={columns}
      dataSource={paymentData}

      pagination={{
        position: ["bottomRight"],
        pageSize: 5,
      }}
    />
        )}
  </Card>
  )
}

export default PaymentHistory

import React from "react";
import { Route, Switch } from "react-router-dom";
import ExporterData from "./exporterdata";

const App = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/`} component={ExporterData} />
  </Switch>
);

export default App;
import {all} from "redux-saga/effects";
import authSagas from "./authSaga";
import myProductSaga from "./myProductsSaga";
import requestQuotationSaga from "./requestQuotationSaga";
import CommonSaga from "./commonSaga";
import pointSaga from "./pointsSaga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    myProductSaga(),
    requestQuotationSaga(),
    CommonSaga(),
    pointSaga(),
    ]);
}

import { POINTS_TYPES } from "../types/pointsTypes";

const INITIAL_STATE = {
  points: 0,
  error: null,
};

export const pointsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POINTS_TYPES.GET_POINTS:
      return {
        ...state,
        error: null,
      };
      case POINTS_TYPES.GET_POINTS_SUCCESS:
        return {
            ...state,
            points: action.payload,
        }
    default:
      return state;
  }
};

import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import SidebarLogo from "./SidebarLogo";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import {
  HomeOutlined,
  LogoutOutlined,
  CommentOutlined,
  ProfileOutlined,
  FileTextOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { isAdminApp, isFreightForwarder, isUserApp } from "../../util/helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  isExporter,
  isBuyer,
  isCHA,
  isShipping,
  isProductApplicable,
  isManufacturerCumExporter,
} from "./../../util/helper";
import { AUTH_TYPES } from "../../appRedux/types/authTypes";
import "./SidebarContent.css";
import { BUSINESS_TYPE } from "../../util/Constant";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const dispatch = useDispatch();
  const { themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const userData = useSelector(({ auth }) => auth?.userData);

  let selectedKeys = pathname.split("/")[1];
  if (selectedKeys === "") {
    selectedKeys = "home";
  }
  const history = useHistory();
  const onLogoutClick = () => {
    dispatch({ type: AUTH_TYPES.LOGOUT_ACTION });
    localStorage.removeItem("token");
    history.replace("/signin");
  };

  const renderUserSideMenu = () => {
    return (
      <>
        <Menu.Item key="home">
          <Link to="/">
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Menu.Item>
        {isProductApplicable(userData) && (
          <Menu.Item key="products">
            <Link to="/my-products">
              <ProfileOutlined />
              <span>My Products</span>
            </Link>
          </Menu.Item>
        )}
        {!isBuyer(userData) && !isManufacturerCumExporter(userData) && (
          <Menu.Item key="enquiries">
            <Link to="/enquiries">
            <FileTextOutlined />
              <span>
                {isCHA(userData) || isShipping(userData) || isExporter(userData)
                  ? "Leads"
                  : "Leads"}
              </span>
            </Link>
          </Menu.Item>
        )}
        {isManufacturerCumExporter(userData) && (
          <>
            <Menu.SubMenu
              key="exporter-role"
              icon={<FileTextOutlined />}
              title="Exporter Role"
              className="custom-submenu"
            >
              <Menu.Item key="buyer-enquiries">
                <Link to={`/enquiries?role=${BUSINESS_TYPE.BUYER}`}>
                  <FileTextOutlined />
                  <span>Buyer Leads</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="rfq">
                <Link to="/rfq">
                  <FileTextOutlined />
                  <span>My RFQ</span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="manufacturer-role"
              icon={<FileTextOutlined />}
              title="Manufacturer Role"
              className="custom-submenu"
            >
              <Menu.Item key="exporter-enquiries">
                <Link to={`/enquiries?role=${BUSINESS_TYPE.EXPORTER}`}>
                  <FileTextOutlined />
                  <span>Exporter Leads</span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </>
        )}

        {(isBuyer(userData) || isExporter(userData)) && (
          <Menu.Item key="rfq">
            <Link to="/rfq">
              <FileTextOutlined />
              <span>My RFQ</span>
            </Link>
          </Menu.Item>
        )}

        {!isBuyer(userData) && (
          <Menu.Item key="promotions">
            <Link to="/promotions">
              <ProfileOutlined />
              <span>My Promotions</span>
            </Link>
          </Menu.Item>
        )}
         {isFreightForwarder(userData) && (
          <Menu.Item key="freight-market-rate-List">
            <Link to="/freight-market-rate-List">
              <ProfileOutlined />
              <span>Updates Freight Rates</span>
            </Link>
          </Menu.Item>
        )}
       {isExporter(userData) && (
          <Menu.Item key="freight-market-watch">
            <Link to="/freight-market-watch">
              <ProfileOutlined />
              <span>Freight Market Watch</span>
            </Link>
          </Menu.Item>
        )}
        {/* {isExporter(userData) && (
          <Menu.Item key="sellRodtep">
            <Link to="/sell-rodtep">
              <ProfileOutlined />
              <span>Sell RODTEP</span>
            </Link>
          </Menu.Item>
        )}
        {isExporter(userData) && (
        <Menu.Item key="unclaimed-cargo">
            <Link to="/unclaimed-cargo">
              <ProfileOutlined />
              <span>Unclaimed Cargo</span>
            </Link>
          </Menu.Item>
            )} */}
        {isExporter(userData) && (
          <Menu.Item key="exporter-Data">
            <Link to="/exporter-Data">
              <ProfileOutlined />
              <span>Exporter Data</span>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="logout">
          <Link to="/signin" onClick={onLogoutClick}>
            <LogoutOutlined />
            <span>Logout</span>
          </Link>
        </Menu.Item>
      </>
    );
  };

  const renderAdminSideMenu = () => {
    return (
      <>
        <Menu.Item key="enquiries">
          <Link to="/enquiries">
            <CommentOutlined />
            <span>Enquiries</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="products">
          <Link to="/products">
            <ProfileOutlined />
            <span>Products</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="users">
          <Link to="/users">
            <UserOutlined />
            <span>Users</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="registrations">
          <Link to="/registrations">
            <HomeOutlined />
            <span>Exporter Registrations</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="website-queries">
          <Link to="/website-queries">
            <HomeOutlined />
            <span>Website Queries</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="promotions">
          <Link to="/promotions">
            <ProfileOutlined />
            <span>Promotions</span>
          </Link>
        </Menu.Item>
      </>
    );
  };

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        {/* <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile/>
          <AppsNavigation/>
        </div> */}
        <Menu
          defaultOpenKeys={[selectedKeys]}
          selectedKeys={[selectedKeys]}
          theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
          mode="inline"
        >
          {isUserApp() ? renderUserSideMenu() : null}
          {isAdminApp() ? renderAdminSideMenu() : null}
        </Menu>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

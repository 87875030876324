import { BASE_API_CONFIG } from "util/Api";
import { NETWORK_METHOD } from "util/ApiHelper";

export const MY_EXIM_API = {
  getLogin: (params) => ({
    method: NETWORK_METHOD.POST,
    url: `${BASE_API_CONFIG.baseURL}/auth/login`,
    data: { username: params.email, password: params.password },
    config: {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    },
  }),
  checkCurrentPassword: (params) => {
    return {
      method: NETWORK_METHOD.POST,
      url: `${BASE_API_CONFIG.baseURL}/auth/currentpassword/check`,
      data: { id: params.id, password: params.password },
      config: {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      },
    };
  },
  createUser: (params) => ({
    method: NETWORK_METHOD.POST,
    url: `${BASE_API_CONFIG.baseURL}/users`,
    data: {
      name: params?.name,
      email: params?.email,
      companyName: params?.company,
      mobile: params?.mobile,
      businessTypeId: params?.businessType,
      country: params?.countryName,
      countryCode: params?.countryCode,
      gstNumber: params?.gstNumber,
      loadingPort:params?.loadingPort,
      portId: params?.portId,
      port: params?.port,
    },
    config: {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    },
  }),
  forgotPassword: (params) => ({
    method: NETWORK_METHOD.POST,
    url: `${BASE_API_CONFIG.baseURL}/users/forgotpassword`,
    data: { email: params.emailId },
  }),
  updatedPassword: (params) => ({
    method: NETWORK_METHOD.POST,
    url: `${BASE_API_CONFIG.baseURL}/users/resetpassword`,
    data: { password: params.password, resetpasswordToken: params.token },
  }),
  sendOTP: (params) => ({
    method: NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/auth/phone/verification?mobile=${params.mobile}`,
  }),
  verifyOTP: (params) => ({
    method: NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/auth/otp/verification?mobile=${params.mobile}&code=${params.code}`,
  }),
  businessType: () => ({
    method: NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/businesstypes`,
  }),
};

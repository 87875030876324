import React, { useCallback } from 'react';
import { Card, Dropdown, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import { formatDate } from "../../util/helper";
import { EyeOutlined, LeftOutlined, MoreOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const UpdateAllfreightrates = () => {
  const history = useHistory();
  const location = useLocation();
  const allFreightRates = location.state?.allFreightRates || [];
  console.log("allFreightRates",allFreightRates)


const authUser =useSelector(({auth})=>auth.userData)
const id =authUser.id;


const handleViewDetails = useCallback((record) => {
      history.push({
        pathname: `/freight-market-watch-Details`,
        state: { allFreightRates: record },
      });
    }, [history]);

const columns = [
    {
      title: <span style={{ fontWeight: "bold" }}>Posted Date</span> ,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => text ? format(new Date(text), 'dd-MMM-yyyy') : '-',
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Loading Country</span>,
      dataIndex: 'loadingCountry',
      key: 'loadingCountry',
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Loading Port</span>,
      dataIndex: 'loadingPort',
      key: 'loadingPort',
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Destination Country</span>,
      dataIndex: 'destinationCountry',
      key: 'destinationCountry',
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Destination Port</span>,
      dataIndex: 'destinationPort',
      key: 'destinationPort',
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Freight Rate </span>,
      key: 'freightRate',
      render: (_, record) => {
        const formattedFreight = new Intl.NumberFormat('en-IN', {
          style: 'decimal',
        }).format(record.oceanfreight);
        return `${record.currency} ${formattedFreight}`;
      },
    },
    {
        title: <span style={{ fontWeight: "bold" }}>Price Validity</span> ,
        dataIndex: 'pricevalidity',
        key: 'pricevalidity',
        render: (text) => text ? format(new Date(text), 'dd-MMM-yyyy') : '-',
      },
    {
      title: <span style={{ fontWeight: "bold" }}>Actions  </span>,
      key: "actions",
      render: (record) => (
        <div
          className="myexim-action-icon"
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown
            menu={{
              items: [
                {
                  label: "View More Details",
                  key: "1",
                  icon: <EyeOutlined />,
                  onClick: () => {
                    handleViewDetails(record);
                  },
                },
              ],
            }}
            trigger={["click"]}
          >
            <MoreOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <Card
    className="myexim-detail-card"
    title={
      <div className="myexim-table-title gx-text-capitalize">
        <LeftOutlined
          className={'back-icon'}
          size="large"
          onClick={() => {
            history.goBack();
          }}
        />
       All Freight Rates
      </div>
    }
  >
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        className="gx-table-responsive rfq-table myexim-table"
        rowKey="userid"
        dataSource={allFreightRates}
        columns={columns}
        pagination={{ pageSize: 8 }}
      />
      </Card>
  );
};

export default UpdateAllfreightrates;
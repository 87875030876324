import { Form, Input, Row, Col, Select, Card, Button, InputNumber, Table, Pagination, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { useHistory, useParams } from "react-router-dom";
import { Option } from "antd/es/mentions";
import { isArrayNonEmpty, isValidElement } from "../../util/ApiHelper";
import { labelComponent } from "./../../util/UtilComponents";
import { isExporter } from "./../../util/helper";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import { axiosInstance } from "../../util/Api";
import { formatDate } from "../../util/helper";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Exporterdata = () => {
  let isEditDisabled = false;

  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = useForm();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();
  const [hsCodeId, setHsCodeId] = useState(null);
  const [filteredHsCodeData, setFilteredHsCodeData] = useState(null);
  const [hsCodeData, setHsCodeData] = useState(null);
  console.log("hsCodeData",setHsCodeData)
  const authUser = useSelector(({ auth }) => auth.userData);
  const productList = useSelector((state) => state.myProducts.productsList);
  const categoryList = useSelector((state) => state.myProducts.categoryList);
  const hsCodeList = useSelector((state) => state.myProducts.hsCodeList || []);
  const countries = useSelector(({ common }) => common.countries);
  const ports = useSelector(({ common }) => common.ports);
  const [targetedBusinessType, setTargetedBusinessType] = useState(null);
  const [sourcePortsData, setSourcePortsData] = useState(null);
  const [selectedSourceCountry, setSelectedSourceCountry] = useState(
    isExporter(authUser) ? "India" : null
  );
const [selectedDestinationCountry, setSelectedDestinationCountry] =
    useState(null);
  const [viewNotification, setViewNotification] = useState(false);
  const [destinationPortsData, setDestinationPortsData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false); 
  const [indianPorts, setIndianPorts] = useState(ports ?? []);
  const roundLoader = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  useEffect(() => {
    if (isArrayNonEmpty(ports)) {
      const filteredIndianPorts = Array.from(
        new Map(
          ports
            .filter((item) => item?.countryId === 105)
            .map((item) => [item.port, item])
        ).values()
      );
      setIndianPorts(filteredIndianPorts);
    }
  }, [ports]);


  const fetchFilteredData = async (filters) => {
    try {
      console.log('Sending request to backend with filters:', filters); 
      setLoading(true);
  
      console.log('Making request to backend...'); 
      const response = await axiosInstance.get('/exim-data/filterdata', {
        params: filters,
      });
      console.log('Response from backend:', response.data); 
      setFilteredData(response.data.data);
    } catch (error) {
      console.error('Error fetching EXIM data:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
const [pageSize, setPageSize] = useState(5);

const handlePageChange = (page, newPageSize) => {
  setCurrentPage(page);
  if (newPageSize !== pageSize) {
    setPageSize(newPageSize);
    setCurrentPage(1);
  }
};

const paginatedData = filteredData.slice(
  (currentPage - 1) * pageSize,
  currentPage * pageSize
);




  
  const handleSearch = (values) => {
    console.log('Form values:', values); 

    const selectedHsCode = hsCodeData.find(hsCode => hsCode.id === values.hsCode);
  
    const filters = {
      hscode: selectedHsCode ? selectedHsCode.code : null, 
      sourceCountry: values.sourceCountry,
      destinationCountry: values.destinationCountry,
      sourcePort: values.sourcePort,
      destinationPort: values.destinationPort,
    };
    console.log('Filters being sent to backend:', filters); 
    setHasSearched(true);
    fetchFilteredData(filters);
  };
  
  const handleDownloadExcel = async () => {
    try {
        const response = await axiosInstance.post(
            '/exim-data/download-excel',
            { data: filteredData }, 
            {
              responseType: 'blob', 
            }
          );
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Exim_data.xlsx');
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };
  
  const handleHSCodeSearch = (value) => {
    const trimmedValue = value.trim();
    const containsNumbers = /^\d+$/.test(trimmedValue.toLowerCase());

    setFilteredHsCodeData(() => {
      const filterFunction = (option) => {
        const codeString = option.code
          .toString()
          .replace(/\s+/g, "")
          .toLowerCase();
        const descriptionString = option.description
          .replace(/[\n\r"]/g, "")
          .toLowerCase();

        return containsNumbers
          ? codeString.includes(trimmedValue.toLowerCase())
          : descriptionString.includes(trimmedValue.toLowerCase());
      };

      return isValidElement(hsCodeData)
        ? hsCodeData.filter(filterFunction)
        : hsCodeList?.filter(filterFunction);
    });
  };

  useEffect(() => {
    if (hsCodeList?.length) {
      setHsCodeData(hsCodeList);
    }
  }, [hsCodeList]);
console.log("hsCodeList",hsCodeList)  

  const handleChangeProductCategory = (value) => {
    form.resetFields(["hsCode"]);
    setHsCodeId(null);
    setFilteredHsCodeData(null);
    setHsCodeData(hsCodeList?.filter((item) => item?.categories?.id === value));
  };

  useEffect(() => {
    const countryId = countries.find(
      (item) => item.country === selectedSourceCountry
    )?.id;
    setSourcePortsData(ports.filter((item) => item.countryId === countryId));
  }, [countries, ports, selectedSourceCountry]);

  useEffect(() => {
      const countryId = countries.find(
        (item) => item.country === selectedDestinationCountry
      )?.id;
      setDestinationPortsData(
        ports.filter((item) => item.countryId === countryId)
      );
    }, [countries, ports, selectedDestinationCountry]);


  const columns = [
    {
        title: 'Date',
        dataIndex: 'DATE',
        key: 'DATE',
        render: (text) => formatDate(text),
        
      },
    {
      title: 'HS Code',
      dataIndex: 'HS_code',
      key: 'HS_code',
    },
    {
      title: 'Product Description',
      dataIndex: 'productDescription',
      key: 'productDescription',
    },
    {
      title: 'Consignee Name',
      dataIndex: 'consigneeName',
      key: 'consigneeName',
    },
    {
      title: 'Port of Destination',
      dataIndex: 'portOfDestination',
      key: 'portOfDestination',
    },
    {
      title: 'Country of Destination',
      dataIndex: 'countryOfDestination',
      key: 'countryOfDestination',
    },
    {
      title: 'Port of Origin',
      dataIndex: 'portOfOrigin',
      key: 'portOfOrigin',
    },
    {
      title: 'Quantity',
      dataIndex: 'QTY',
      key: 'QTY',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
  ];


  const renderMobileView = useCallback(
    (data) =>
      data.map((item) => (
        <div
          key={item.DATE}
          style={{
            marginBottom: "16px",
            borderRadius: "8px",
            padding: "12px",
            borderBottom: "1px solid #f0f0f0"

          }}
        >
          <div style={{ 
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
            paddingBottom: "8px",
          }}>
            <div style={{ fontSize: "16px", fontWeight: "bold", color: "#666" }}>
              Date - {formatDate(item.DATE)}
            </div>
            <div style={{ fontSize: "14px", color: "#666" }}>
              <strong>QTY</strong>
            </div>
          </div>
  
          <div style={{ 
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}>
            <div style={{ fontSize: "14px", color: "#666" }}>
              <strong>HS Code -</strong> 
              {item.HS_code}
            </div>
            <div style={{ fontSize: "14px", color: "#666" }}>
              {item.QTY}
            </div>
          </div>
  
          <div style={{ 
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}>
            <div style={{ fontSize: "14px", color: "#666" }}>
               {item.portOfOrigin}
            </div>
            <div style={{ fontSize: "14px", color: "#666" }}>
              {item.rateCurrency}
            </div>
          </div>
  
          <div style={{ fontSize: "14px", color: "#666" }}>
            {item.portOfDestination}
          </div>
        </div>
      )),
    []
  );


  return (
    <Card className="myexim-detail-card">
      <div className="myexim-table-title">
        {
          "Exporter Data"
        }
      </div>

      <Form form={form} onFinish={handleSearch} className="gx-pt-4">
        <Row gutter={16}>

        <Col xs={24} sm={12} md={12}>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label={labelComponent(
                              "Country Of Origin",
                              "Currently we are allowing Loading country as India only"
                            )}
                            name="sourceCountry"
                            rules={[
                              {
                                required: !isExporter(authUser),
                                message: "Loading Country is required",
                              },
                            ]}
                          >
                            <Select
                              defaultValue={
                                isExporter(authUser)  ? "India" : null
                              }
                              disabled={
                                isEditDisabled ||
                                isExporter(authUser)
                              }
                              showSearch
                              size="large"
                              getPopupContainer={(trigger) => trigger.parentNode}
                              value={selectedSourceCountry}
                              placeholder="Select Loading Country"
                            >
                              {countries?.map(
                                (option) =>
                                  option.country !== "" && (
                                    <Option key={option.country} value={option.country}>
                                      {option.country}
                                    </Option>
                                  )
                              )}
                            </Select>
                          </Form.Item>
                        </Col>

          <Col xs={24} sm={12} md={12}>
                          <Form.Item
                            disabled={isEditDisabled}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label={labelComponent(
                              "HS Code / Item Description",
                            )}
                            name="hsCode"
                            rules={[
                              {
                                required: true,
                                message: "HS code is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              size="large"
                              name="hsCode"
                              placeholder="Select HS code / Item Description"
                              filterOption={false}
                              dropdownStyle={{ maxHeight: "200px", overflowY: "auto" }}
                              getPopupContainer={(trigger) => trigger.parentNode}
                              disabled={isEditDisabled}
                              onChange={(value) => {
                                setHsCodeId(value);
                              }}
                              onSearch={handleHSCodeSearch}
                            >
                              {isValidElement(filteredHsCodeData)
                                ? filteredHsCodeData?.map((option) => (
                                    <Option key={option.id} value={option.id}>
                                      <div style={{ whiteSpace: "normal" }}>
                                        {option.code} -{" "}
                                        {option.description.replace(/[\n\r"]/g, "")}
                                      </div>
                                    </Option>
                                  ))
                                : hsCodeData?.map((option) => (
                                    <Option key={option.id} value={option.id}>
                                      <div style={{ whiteSpace: "normal" }}>
                                        {option.code} -{" "}
                                        {option.description.replace(/[\n\r"]/g, "")}
                                      </div>
                                    </Option>
                                  ))}
                            </Select>
                          </Form.Item>
                        </Col>

                         <Col xs={24} sm={12} md={12}>
                                        <Form.Item
                                          disabled={isEditDisabled}
                                          labelCol={{ span: 24 }}
                                          wrapperCol={{ span: 24 }}
                                          label={labelComponent(
                                            "Port Of Origin",
                                            "Select the Loading Port where Goods to be loaded"
                                          )}
                                          name="sourcePort"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Loading Port is required",
                                            },
                                          ]}
                                        >
                                          <Select
                                            showSearch
                                            virtual={false}
                                            size="large"
                                            placeholder="Select Loading Port"
                                            disabled={!selectedSourceCountry}
                                            filterOption={(input, option) => {
                                                const label = option.children;
                                                return label.toLowerCase().includes(input.toLowerCase());
                                              }}
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                          >
                                            { indianPorts?.map((option) => (
                          <Option key={option.port} value={option.port}>
                             {`${option.port} (${option.code})`}
                          </Option>
                        ))
                        }
                                          </Select>
                                        </Form.Item>
                                      </Col>

          <Col xs={24} sm={12} md={12}>
                          <Form.Item
                            disabled={isEditDisabled}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label={labelComponent(
                              "Destination Country",
                              "Select the Destination Country where Goods to be Delivered"
                            )}
                            name="destinationCountry"
                            rules={[
                              {
                                required: true,
                                message: "Destination Country is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              size="large"
                              placeholder="Select Destination Country"
                              onChange={(value) => {
                                setSelectedDestinationCountry(value); 
                              }}
                              value={selectedDestinationCountry}
                              getPopupContainer={(trigger) => trigger.parentNode}
                            >
                              {countries?.map((option) => (
                                <Option key={option.country} value={option.country}>
                                  {option.country}
                                </Option>
                              ))}
                              
                            </Select>
                          </Form.Item>
                        </Col>

 <Col xs={24} sm={12} md={12}>
                <Form.Item
                  disabled={isEditDisabled}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent(
                    "Destination Port",
                    "Select the Destination Port where Goods to be Delivered"
                  )}
                  name="destinationPort"
                  rules={[
                    {
                      required: true,
                      message: "Destination Port is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select Destination Port"
                    disabled={!selectedDestinationCountry} 
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    name="destinationPort"
                    rules={[
                      {
                        required: true,
                        message: "Destination Port is required",
                      },
                    ]}
                  >
                    {isValidElement(destinationPortsData)
                      ? destinationPortsData?.map((option) => (
                          <Option key={option.port} value={option.port}>
                            {`${option.port}(${option.code})`}
                          </Option>
                        ))
                      : ports?.map((option) => (
                          <Option key={option.port} value={option.port}>
                            {`${option.port}(${option.code})`}
                          </Option>
                        ))
                        }
                  </Select>
                </Form.Item>
              </Col>
        </Row>
        <Row className="gx-mt-4">
          <Col span={24}>
            <Form.Item
              className="gx-text-center gx-mr-3"
              style={{
                justifyContent: isMobile ? "center" : "end",
                display: "flex",
                flex: 1,
              }}
            >
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: 150 }}
              >
                Search
              </Button>
              <Button
                type="primary"
                onClick={() => {
                    if (!hasSearched) {
                      notification.info({
                        description: 'Kindly search for the Exporter Data and proceed with downloading the data.',
                      });
                      return;
                    }
                    handleDownloadExcel();
                  }}                style={{ width: 150 ,height:40}}

              >
                Download Excel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {hasSearched ? (
        loading ? (
            <div style={{ textAlign: "center", padding: "20px" }}>
             <Spin indicator={roundLoader} tip="Loading..." size="large" />          </div>
          ):
      filteredData.length > 0 ? (
        isMobile ? (
          <>
            <div className="mobile-exporter-list">{renderMobileView(paginatedData)}</div>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={filteredData.length}
              onChange={handlePageChange}
              showSizeChanger={true}
              showQuickJumper={false}
              simple
              style={{ marginTop: "16px", textAlign: "center" }}
            />
          </>
        ) : (
          <Table
            columns={columns}
            dataSource={filteredData}
            rowKey="recordId"
            pagination={{ pageSize: 5 }}
            scroll={{ x: true }}
          />
        )
      ) : (
        <div style={{ textAlign: "center", padding: "20px", fontSize: "16px", color: "#888" }}>
          No Data Found
        </div>
      )
    ) : (
      <div style={{ textAlign: "center", padding: "20px", fontSize: "16px", color: "#888" }}>
            First Search Exporter Data & Download Instantly
      </div>
    )}
    </Card>
  );
};

export default Exporterdata;

import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Image, List } from "antd";
import {
  isArrayNonEmpty,
  isValidDate,
  isValidElement,
  isValidString,
  safeStringValue,
} from "../../util/ApiHelper";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined } from "@ant-design/icons";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { EnquiryStatus } from "../../util/Constant";
import { getNewLine } from "util/helper";
import RFQStatusTags from "./RFQStatus";
import { BUSINESS_TYPE_LABEL, BUSINESS_TYPE } from "./../../util/Constant";
import { isBuyer } from "../../util/helper";
import { startCase } from "lodash";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";

const RFQView = () => {
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();
  const dispatch = useDispatch();
  const { id } = useParams();
  const moment = require("moment");
  const userData = useSelector(({ auth }) => auth?.userData);
  const [rfqData, setRfqData] = useState(null);
  const [fileName, setFileName] = useState(null);

  const getRFQById = useCallback(
    async (id) => {
      if (isValidString(id)) {
        dispatch(fetchStart());
        try {
          const rfqById = await axiosInstance.get(
            `${BASE_API_CONFIG.baseURL}/enquiries/${id}`
          );
          setRfqData(rfqById?.data?.data);
        } catch (error) {
        } finally {
          dispatch(fetchSuccess());
        }
      }
    },
    [dispatch]
  );

  const cargoReadinessDate = moment(rfqData?.cargoReadinessDate).format(
    "DD-MMM-YYYY"
  );

  useEffect(() => {
    getRFQById(id);
  }, [getRFQById, id]);

  useEffect(() => {
    if (isArrayNonEmpty(rfqData?.productImages)) {
      const fileNames = rfqData?.productImages?.map((image) => {
        return image;
      });
      setFileName(fileNames);
    }
  }, [rfqData]);

  const data = [
    {
      label: "Product Name",
      value: rfqData?.productName,
    },
    ...(rfqData?.categories?.name
      ? [
          {
            label: "Product Category",
            value: rfqData.categories.name,
          },
        ]
      : []),
      ...(rfqData?.hscode?.code
        ? [
    {
      label: "HS code / Item Description",
      value: `${rfqData?.hscode?.code} - ${rfqData?.hscode?.description}`,
    },
  ]
  :[]),
    ...(isValidElement(rfqData?.netWeightPerContainer)
      ? [
          {
            label: "Net Weight Per Container",
            value: `${rfqData?.netWeightPerContainer} Kilograms`,
          },
        ]
      : []),
    ...(isValidElement(rfqData?.shipmentMode) &&
    (rfqData?.targetedBusinessType?.[0] === BUSINESS_TYPE.CUSTOM_HOUSE_AGENT ||
      rfqData?.targetedBusinessType?.[0] === BUSINESS_TYPE.FREIGHT_FORWARDER ||
      rfqData?.targetedBusinessType?.[0] === BUSINESS_TYPE.SHIPPING_LINE)
      ? [
          {
            label: "Shipping Mode",
            value: startCase(rfqData?.shipmentMode),
          },
        ]
      : []),
    ...(isValidElement(rfqData?.totalCubicMeter) ||
    isValidElement(rfqData?.noOfContainers)
      ? [
          {
            label: "Quantity",
            value:
              safeStringValue(rfqData?.shipmentMode) === "air"
                ? `${rfqData?.totalCubicMeter} Cubic Meter`
                : rfqData?.noOfContainers +
                  " " +
                  (rfqData?.noOfContainers > 1 ? "Containers" : "Container") +
                  (rfqData?.containerType ? `(${rfqData?.containerType})` : ""),
          },
        ]
      : []),
    ...(rfqData?.targetedBusinessType?.[0] === BUSINESS_TYPE.COMMISSION_AGENT ||
    rfqData?.targetedBusinessType?.[0] === BUSINESS_TYPE.MANUFACTURER
      ? [
          {
            label: "Delivery Terms",
            value: `${safeStringValue(
              rfqData?.paymentTerms
            )} - ${safeStringValue(rfqData?.locationForDelivery)}`,
          },
        ]
      : []),
    {
      label: "Requirements",
      value: getNewLine(rfqData?.description),
    },
    {
      label: "Source Country",
      value: rfqData?.sourceCountry,
    },
    {
      label: "Source Port",
      value: rfqData?.sourcePort,
    },
    {
      label: "Destination Country",
      value: rfqData?.destinationCountry,
    },
    {
      label: "Destination Port",
      value: rfqData?.destinationPort,
    },
    {
      label: "Status",
      value: <RFQStatusTags status={rfqData?.status} />,
    },
    ...(isValidDate(cargoReadinessDate)
      ? [
          {
            label: "Cargo Readiness Date",
            value: cargoReadinessDate,
          },
        ]
      : []),
    {
      label: "Remarks",
      value: rfqData?.remarks,
    },
    ...(isValidElement(rfqData?.currency)
      ? [
          {
            label: "Currency",
            value: rfqData?.currency,
          },
        ]
      : []),
    ...(isValidElement(rfqData?.rateindication)
      ? [
          {
            label: "Rate Indication",
            value: rfqData?.rateindication,
          },
        ]
      : []),
  ];
  if (!isBuyer(userData)) {
    data.unshift({
      label: "Party",
      value: BUSINESS_TYPE_LABEL[rfqData?.targetedBusinessType?.[0]],
    });
  }

  //26000 netWeightPerContainer

  const navigateProfileEdit = () => {
    history.push(`/rfq/details/${id}`);
  };
  console.log("rfqData", rfqData);
  return (
    isValidElement(rfqData) && (
      <Card className="myexim-detail-card">
        <div className="myexim-table-title">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />{" "}
          Enquiry Details
        </div>
        <div className="logo-with-list gx-mt-4">
          {isValidString(rfqData?.primaryImage) && (
            <div className={"logo-container"}>
              <div className={"logo-image"}>
                <img
                  alt="..."
                  src={rfqData?.primaryImage}
                  width={120}
                  height={120}
                />
              </div>
            </div>
          )}
          <div style={{ flex: 1 }}>
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) =>
                item?.value && (
                  <List.Item className="myexim-details-list">
                    <List.Item.Meta
                      title={<div>{item.label}</div>}
                      description={item.value}
                    />
                  </List.Item>
                )
              }
            />
            {fileName?.length > 0 && (
              <div className="attachments">
                <div className="sub-header"> Attachments</div>
                <div className="gx-pt-4">
                  {fileName?.map((fileName, index) => (
                    <div key={index} className="gx-pb-3">
                      <Image
                        className="gx-rounded-lg"
                        width={isMobile ? "100%" : 150}
                        height={isMobile ? "100%" : 150}
                        src={fileName?.src}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{ justifyContent: "center", display: "flex", flex: 1 }}
          className="gx-pt-4"
        >
          <Button
            type="secondary"
            size="large"
            onClick={() => {
              history.goBack();
            }}
            className="submit-btn"
          >
            Back
          </Button>
          {rfqData?.totalQuotations > 0 ? (
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              onClick={() => {
                history.push({
                  pathname: `/rfq/quotations-list/${id}`,
                  state: {
                    party: rfqData?.targetedBusinessType?.[0],
                  },
                });
              }}
              className="submit-btn"
            >
              View Quotations
            </Button>
          ) : (
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              onClick={navigateProfileEdit}
              className="submit-btn"
              disabled={rfqData?.status === EnquiryStatus.PUBLISHED}
            >
              Edit
            </Button>
          )}
        </div>
      </Card>
    )
  );
};

export default RFQView;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, List } from "antd";
import { isValidElement, isValidString } from "../../util/ApiHelper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CERT_KEY } from "../../util/Constant";
import { isExporter, isBuyer, isCHA, isManufacturerCumExporter } from "./../../util/helper";
import VerificationBanner from "../home/setup/VerificationBanner";
import ProfileUpdatePasswordModal from "./ProfileUpdatePasswordModal";
import { resetPasswordToken } from "../../appRedux/actions/authAction";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import MyBalance from "./MyBalance";
import MyActivity from "./MyActivity";
import PaymentHistory from './PaymentHistory'
import axios from "axios";
import { BASE_API_CONFIG } from "../../util/Api";
import { getUpdatedPoints } from "../../appRedux/actions/pointsAction";

export const getAttchementByKey = (user, key) => {
  if (isValidElement(user?.attachments)) {
    return user?.attachments.find((attachment) => attachment.key === key);
  }
};

const MyProfileView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();
  const authUser = useSelector(({ auth }) => auth.userData);
  console.log("authUser",authUser)
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const userData = useSelector(({ auth }) => auth?.userData);
  const [pointsData, setPointsData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const uuid = authUser?.uuid;
  const points = useSelector((state) => state.points.points)

  const fetchPointsData = useCallback(async () => {
    try {
      const uuid = authUser?.uuid;
      if (uuid) {
        const response = await axios.get(
          `${BASE_API_CONFIG.baseURL}/pointshistory/${uuid}`
        );

        const formattedData = response.data.data.rows.map((point) => ({
          date: new Date(point.transactiondate).toLocaleDateString(),
          transactionname: point.transactionname,
          transactiontype: point.transactiontype,
          pointsconsumed: point.pointsconsumed,
        }));
        setPointsData(formattedData);
      }
    } catch (err) {
      console.error("Error fetching points data:", err);
    }
  }, [uuid]);

  useEffect(() => {
    if (uuid) {
      dispatch(getUpdatedPoints({ uuid }));
      fetchPointsData();
    }
  }, [uuid,dispatch,fetchPointsData]);



  const fetchPaymenthistory = useCallback(async () => {
    try {
      const uuid = authUser?.uuid;
      if (uuid) {
        const response = await axios.get(
          `${BASE_API_CONFIG.baseURL}/paymenthistory/${uuid}`
        );

          if (response?.data?.data?.rows?.length) {

        const formattedData = response.data.data.rows.map((point,index) => ({
          key: index,
          transactiondate: new Date(point.transactiondate).toLocaleDateString(),
          description: point.transactionname,
          currency: point.currency,
          hostPaymentid: point.hostpaymentid,
          status: point.status,
          transactionamount: point.transactionamount,
          Points: point.transactionamount / 100,

        }));
        setPaymentData(formattedData);

         } else {
          console.error("Unexpected response structure:", response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching points data:", error);
    }
  }, [uuid]);

  const refreshBalance = () => {
    if (uuid) {
      dispatch(getUpdatedPoints({ uuid }));
      fetchPointsData();
      fetchPaymenthistory()
    }
  };

  useEffect(() => {
    if (uuid) {
      dispatch(getUpdatedPoints({ uuid }));
      fetchPaymenthistory()
    }
  },[uuid, dispatch,fetchPaymenthistory,points])

  useEffect(() => {
    dispatch(resetPasswordToken());
  }, []);

  const getAttachments = () => {
    if (isBuyer(authUser)) {
      const certificate = getAttchementByKey(
        authUser,
        CERT_KEY.COMPANY
      )?.originalName;

      return certificate ? (
        <a
          href={getAttchementByKey(authUser, CERT_KEY.COMPANY)?.src}
          download
          target="_blank"
          rel="noreferrer"
        >
          {certificate}
        </a>
      ) : (
        "Not available"
      );
    } else if (isExporter(authUser)) {
      const certificate = getAttchementByKey(
        authUser,
        CERT_KEY.GST
      )?.originalName;

      return certificate ? (
        <a
          href={getAttchementByKey(authUser, CERT_KEY.GST)?.src}
          download
          target="_blank"
          rel="noreferrer"
        >
          {certificate}
        </a>
      ) : (
        "Not available"
      );
    } else {
      return "Not available";
    }
  };

  const getEximCertificate = () => {
    const certificate = getAttchementByKey(
      authUser,
      CERT_KEY.EXIM
    )?.originalName;

    if (isExporter(authUser)) {
      return certificate ? (
        <a
          href={getAttchementByKey(authUser, CERT_KEY.EXIM)?.src}
          download
          target="_blank"
          rel="noreferrer"
        >
          {certificate}
        </a>
      ) : (
        "Not available"
      );
    }
  };

  const data = [
    {
      label: "Name",
      value: authUser?.name,
    },
    {
      label: "Company",
      value: authUser?.companyName,
    },
    {
      label: "Phone",
      value: authUser?.mobile,
    },
    {
      label: "Email",
      value: authUser?.email,
    },
    {
      label: "City",
      value: authUser?.city,
    },
    {
      label: "Country",
      value: authUser?.country,
    },
    {
      label: "Description",
      value: authUser?.companyDescription,
    },
    {
      label: isBuyer(authUser) ? "Company Certificate" : "GST Certificate",
      value: getAttachments(),
    },
    {
      label: isExporter(authUser) && "EXIM Certificate",
      value: isExporter(authUser) && getEximCertificate(),
    },
    ...(isValidElement(authUser?.port) && (isExporter(authUser) || isManufacturerCumExporter(authUser))
    ? [{
        label:"Destination Ports",
        value: Array.isArray(authUser?.port) 
          ? authUser.port.join(", ") 
          : (typeof authUser?.port === "string" 
              ? authUser.port.replace(/[\[\]"]/g, '').replace(/,/g, ', ')
              : ''), 
      }]
    : []
  ),
  ...(isValidElement(authUser?.port) && (isExporter(authUser) || isManufacturerCumExporter(authUser))
  ? [{
      label:"Loading Ports",
      value: authUser?.loadingPort,
    }]
  : []
),
    ...(isValidElement(authUser?.port) && (isExporter(authUser) || isManufacturerCumExporter(authUser))
    ? [{
        label:"Destination Ports",
        value: Array.isArray(authUser?.port) 
          ? authUser.port.join(", ") 
          : (typeof authUser?.port === "string" 
              ? authUser.port.replace(/[\[\]"]/g, '').replace(/,/g, ', ')
              : ''), 
      }]
    : []
  ),
  ];
  const navigateProfileEdit = () => {
    history.push(`/my-profile/edit`);
  };

  const openUpdatePasswordModal = () => {
    setShowUpdatePasswordModal(true);
  };

  return (
    isValidElement(authUser) && (
      <div>
        <div>
          {authUser?.status !== "approved" && (
            <VerificationBanner showButton={false} />
          )}
          <Card className="myexim-detail-card">
            {/* <div className="myexim-table-title">
            My Profile
          </div> */}
            <div
              className={`logo-with-list gx-mt-4 ${
                isMobile ? "gx-flex-column" : ""
              }`}
            >
              <div style={{ paddingRight: 16 }}>
                {isValidString(authUser?.companyLogo) && (
                  <div className={"logo-container"}>
                    <div className={"logo-image"}>
                      <img
                        alt="..."
                        src={authUser?.companyLogo}
                        width={isMobile ? "100%" : 120}
                        height={isMobile ? "100%" : 120}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div style={{ flex: 1 }}>
                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item className="myexim-details-list">
                      <List.Item.Meta
                        key={item.label}
                        title={<div>{item.label}</div>}
                        description={
                          isValidString(item.value) ? item.value : "-"
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>
            <div
              style={{
                justifyContent: isMobile ? "center" : "end",
                display: "flex",
                flex: 1,
                flexWrap: "wrap",
              }}
              className="gx-pt-4"
            >
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                onClick={openUpdatePasswordModal}
                className="submit-btn"
              >
                Change Password
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                onClick={navigateProfileEdit}
                className="submit-btn"
              >
                Edit
              </Button>
            </div>
            {showUpdatePasswordModal ? (
              <ProfileUpdatePasswordModal
                showModal={showUpdatePasswordModal}
                onCancel={() => {
                  dispatch(resetPasswordToken());
                  setShowUpdatePasswordModal(false);
                }}
              />
            ) : null}
          </Card>
          {!isBuyer(userData) && (
            <MyBalance
              currentBalance={points}
              refreshBalance={refreshBalance}
            />
          )}
          {!isBuyer(userData) && <MyActivity pointsData={pointsData} />
           }
           {!isBuyer(userData) &&<PaymentHistory paymentData={paymentData} />
           }
        </div>
      </div>
    )
  );
};

export default MyProfileView;

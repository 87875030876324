import React, { useCallback, useEffect, useState } from 'react';
import { axiosInstance, BASE_API_CONFIG } from '../../util/Api';
import { useDispatch, useSelector } from 'react-redux';
import { EyeOutlined, LeftOutlined, MoreOutlined } from '@ant-design/icons';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, Dropdown, message, Table } from 'antd';
import { format } from 'date-fns';
import { isValidString } from '../../util/ApiHelper';
import { fetchStart, fetchSuccess } from "../../appRedux/actions";


const Updatefreightexporter = () => {
  const authUser = useSelector(({ auth }) => auth.userData);
  const [freightRates, setFreightRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();


  


  console.log('Auth User', authUser.port);

  const fetchFreightRates = async (destinationPort) => {
    console.log('Destination Port', destinationPort);
    try {
      const response = await axiosInstance.get(
        `${BASE_API_CONFIG.baseURL}/updates-freight-rates/${destinationPort}`,
      );
      if ( response.data.length === 0) {
        message("No Data Found For Your Port")
      }
      return response.data|| [];
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to fetch data');
    }
  };

  const fetchAllFreightRates = async (destinationPort) => {
    try {
      const response = await axiosInstance.get(
        `${BASE_API_CONFIG.baseURL}/updates-freight-rates/${destinationPort}`,
      );
      return response.data.rows ||[];
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to fetch data');
    }
  };

  // const handleNavigation = history.push("/freight-market-watch-details", { freightRates });


  useEffect(() => {
    const loadFreightRates = async () => {
        dispatch(fetchStart());
      if (!authUser?.port) {
        setError('User port not available');
        return;
      }
      try {
        setLoading(true);
        const data = await fetchFreightRates(authUser.port);
        const lowestFreightRates = data.rows
        .sort((a, b) => parseFloat(a.oceanfreight) - parseFloat(b.oceanfreight))
        .slice(0, 5); // Get only the first 5 lowest rates
        setFreightRates(lowestFreightRates);
        setError('');
        dispatch(fetchSuccess());
      } catch (err) {
        setError(err.message || 'Failed to load freight rates');
        
      } finally {
        setLoading(false);
      }
    };
    loadFreightRates();
  }, [authUser]);


   const navigateToDetails = useCallback(
      (destinationPort) => {
        if (isValidString(destinationPort) && isValidString(destinationPort)) {
          history.push(`freight-market-watch/freight-market-watch-details`);
        } else if (isValidString(destinationPort)) {
          history.push(`freight-market-watch/freight-market-watch-details`);
        }
      },
      [history]
    );

    const id =authUser.id;
    
    const handleViewDetails = useCallback((record) => {
      history.push({
        pathname: `/freight-market-watch-Details/${id}`,
        state: { freightRate: record },
      });
    }, [history]);

    const handleViewMoreQuotes = useCallback(async () => {
      try {
        setLoading(true);
        const data = await fetchAllFreightRates(authUser.port);
        history.push({
          pathname: '/freight-market-watch-all-details/all-quotes',
          state: { allFreightRates: data },
        });
      } catch (err) {
        setError(err.message || 'Failed to load freight rates');
      } finally {
        setLoading(false);
      }
    }, [authUser.port, history]);
  

  const columns = [
    {
      title: <span style={{ fontWeight: "bold" }}>Posted Date</span> ,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => text ? format(new Date(text), 'dd-MMM-yyyy') : '-',
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Loading Country</span>,
      dataIndex: 'loadingCountry',
      key: 'loadingCountry',
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Loading Port</span>,
      dataIndex: 'loadingPort',
      key: 'loadingPort',
    },
    
    {
      title: <span style={{ fontWeight: "bold" }}>Destination Country</span>,
      dataIndex: 'destinationCountry',
      key: 'destinationCountry',
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Destination Port</span>,
      dataIndex: 'destinationPort',
      key: 'destinationPort',
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Freight Rate </span>,
      key: 'freightRate',
      render: (_, record) => {
        const formattedFreight = new Intl.NumberFormat('en-IN', {
          style: 'decimal',
        }).format(record.oceanfreight);
        return `${record.currency} ${formattedFreight}`;
      },
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Price Validity</span> ,
      dataIndex: 'pricevalidity',
      key: 'pricevalidity',
      render: (text) => text ? format(new Date(text), 'dd-MMM-yyyy') : '-',
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Actions  </span>,
      key: "actions",
      render: (record) => (
        <div
          className="myexim-action-icon"
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown
            menu={{
              items: [
                {
                  label: "View More Details",
                  key: "1",
                  icon: <EyeOutlined />,
                  onClick: () => {
                    handleViewDetails(record);
                  },
                },
                {
                  label: "View More Quotes",
                  key: "1",
                  icon: <EyeOutlined />,
                  onClick: () => {
                    handleViewMoreQuotes();
                  },
                },
              ],
            }}
            trigger={["click"]}
          >
            <MoreOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  if (error) return <div>Error: {error}</div>;

  return (
    <Card
      className="myexim-detail-card"
      title={
        <div className="myexim-table-title gx-text-capitalize">
          <LeftOutlined
            className={'back-icon'}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          Freight Rates
        </div>
      }
    >
      {/* !loading && ( */}
      {freightRates.length === 0 ? (
        
        <div>No freight rates found.</div>
      ) : (
        <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        className="gx-table-responsive rfq-table myexim-table"
          rowKey="userid"
          dataSource={freightRates}
          columns={columns}
          pagination={false} 
          onRow={(record) => {
            return {
              onClick: (e) => {
                if (
                  !e.target.classList.contains(
                    "ant-dropdown-menu-title-content"
                  )
                ) {
                  handleViewDetails(record);
                }
              },
            };
          }}
        />
      )}
    </Card>
  );
};

export default Updatefreightexporter;
import { LeftOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form';
import React, { isValidElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { labelComponent } from '../../util/UtilComponents';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetBreakpoints } from '../../hooks/useGetBreakpoints';
import { axiosInstance, BASE_API_CONFIG } from '../../util/Api';
import {
  isCHA,
  isManufacturer,
  isShippingLine,
  isFreightForwarder,
  showAlert,
} from "../../util/helper";
import { isArrayNonEmpty } from '../../util/ApiHelper';

const Updatesfreightrates = () => {

      const [dataId, setDataId] = useState();
      const [form] = useForm();
      const [selectedDestinationCountry, setSelectedDestinationCountry] =
          useState(null);
      let isEditDisabled = false;
      const countries = useSelector(({ common }) => common.countries);
      const ports = useSelector(({ common }) => common.ports);

      const [indianPorts, setIndianPorts] = useState(ports ?? []);
      
       useEffect(() => {
              if (isArrayNonEmpty(ports)) {
                const filteredIndianPorts = Array.from(
                  new Map(
                    ports
                      .filter((item) => item?.countryId === 105)
                      .map((item) => [item.port, item])
                  ).values()
                );
                setIndianPorts(filteredIndianPorts);
              }
            }, [ports]);

      const { Option } = Select;
      const history = useHistory();
      const { isMobile } = useGetBreakpoints();
      const authUser = useSelector(({ auth }) => auth.userData);

       useEffect(() => {
          const countryId = countries.find(
            (item) => item.country === selectedDestinationCountry
          )?.id;
          setDestinationPortsData(
            ports.filter((item) => item.countryId === countryId)
          );
        }, [countries, ports, selectedDestinationCountry]);
      




const handleChangeDestinationCountry = (value) => {
  setSelectedDestinationCountry(value);
  const countryId = countries.find((item) => item.country === value)?.id;

  const filteredPorts = ports.filter((port) => port.countryId === countryId);
  setDestinationPortsData(filteredPorts);

  form.resetFields(["destinationPort"]);
};


      const validatePortSelection = async (_, value) => {
        const sourcePort = form.getFieldValue("sourcePort");
        if (value === sourcePort) {
          throw new Error("Source Port and Destination Port cannot be the same");
        }
        return;
      };
     const [destinationPortsData, setDestinationPortsData] = useState(null);
     const validateTextArea = (_, value) => {
        if (value && value.length > 1000) {
          return Promise.reject(
            new Error("Product Requirements cannot exceed 1000 characters")
          );
        }
        return Promise.resolve();
      };

     const userData = useSelector(({ auth }) => auth?.userData);
       console.log("authUser",userData)
    const username=useSelector(({ auth }) => auth?.userData.name)
    const userid=useSelector(({ auth }) => auth?.userData.id)

    console.log("username",username)
    console.log("userid",userid)

      const onFinish = (values) => {
          console.log('Form values1:', values); 

      const data ={
        loadingCountry: values?.loadingCountry,
        loadingPort: values?.loadingPort,
        destinationCountry: values?.destinationCountry,
        destinationPort: values?.destinationPort,
        oceanfreight: values?.oceanfreight,
        comments: values?.comments,
        username: username,
        userid: userid,
        currency: values?.currency,
        pricevalidity: values?.pricevalidity,


      }
      console.log('Form values2:', data);

      handleFormSubmit(data);
      }

      const handleFormSubmit = async (data) => {
        console.log("handleFormSubmit",data)
        try {
          const response = await axiosInstance({
            method: "post",
            url: `${BASE_API_CONFIG.baseURL}/updates-freight-rates`,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          });
          console.log("Response from backend:", response.data);
          history.goBack();
        } catch (error) {
          console.error("Error submitting data:", error);
        }
      };      
    
  return (
    <Card
    className="myexim-detail-card"
    title={
      <div className="myexim-table-title gx-text-capitalize">
        <LeftOutlined
          className={"back-icon"}
          size="large"
        />
        {dataId ? "Edit" : ""} Updates Freight Rates
      </div>
    }
  >
       <Form form={form} onFinish={onFinish} initialValues={{ currency: "USD" }} >
       <Row gutter={16}>

              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent(
                    "Loading Country",
                    "Currently we are allowing Loading country as India only"
                  )}
                  name="loadingCountry"
                  rules={[
                    {
                      required: !isFreightForwarder(authUser),
                      message: "Loading Country is required",
                    },
                  ]}
                  initialValue={isFreightForwarder(authUser) ? "India" : undefined}

                >
                  <Select
                    defaultValue={
                      isFreightForwarder(authUser) ? "India" : null
                    }
                    disabled={
                      isEditDisabled ||
                      isFreightForwarder(authUser) 
                    }
                    showSearch
                    size="large"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    placeholder="Select Loading Country"
                  >
                    {countries?.map(
                      (option) =>
                        option.country !== "" && (
                          <Option key={option.country} value={option.country}>
                            {option.country}
                          </Option>
                        )
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  disabled={isEditDisabled}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent(
                    "Loading Port",
                    "Select the Loading Port where Goods to be loaded"
                  )}
                  name="loadingPort"
                  rules={[
                    {
                      required: true,
                      message: "Loading Port is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    virtual={false}
                    size="large"
                    placeholder="Select Loading Port"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    { indianPorts?.map((option) => (
                          <Option key={option.port} value={option.port}>
                             {`${option.port} (${option.code})`}
                          </Option>
                        ))
                        }
                  </Select>
                </Form.Item>
              </Col>

       <Col xs={24} sm={12} md={12}>
            <Form.Item
              disabled={isEditDisabled}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent(
                "Destination Country",
                "Select the Destination Country where Goods to be Delivered"
              )}
              name="destinationCountry"

              rules={[
                {
                  required: true,
                  message: "Destination Country is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select Destination Country"
                onChange={handleChangeDestinationCountry}
                value={selectedDestinationCountry}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {countries?.map((option) => (
                  <Option key={option.country} value={option.country}>
                    {option.country}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

           <Col xs={24} sm={12} md={12}>
                          <Form.Item
                            disabled={isEditDisabled}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label={labelComponent(
                              "Destination Port",
                              "Select the Destination Port where Goods to be Delivered"
                            )}
                            name="destinationPort"
                            rules={[
                              {
                                required: true,
                                message: "Destination Port is required",
                              },
                              { validator: validatePortSelection },
                            ]}
                          >
                            <Select
                              showSearch
                              size="large"
                              placeholder="Select Destination Port"
                              disabled={!selectedDestinationCountry}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              getPopupContainer={(trigger) => trigger.parentNode}
                              name="destinationPort"
                              rules={[
                                {
                                  required: true,
                                  message: "Destination Port is required",
                                },
                              ]}
                            >
                              {
                              
                                destinationPortsData?.map((option) => (
                                    <Option key={option.port} value={option.port}>
                                      {`${option.port}(${option.code})`}
                                    </Option>
                                  ))
                                  }
                            </Select>
                          </Form.Item>
                        </Col>

  <Col span={24} xs={24} sm={12}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label={labelComponent("Ocean Freight", "Offer Price")}
                    required={true}
                  >
                    <Input.Group compact>
                    <div style={{display:"flex"}}>
                      <Form.Item
                        name="currency"
                        label="Currency"
                        noStyle
                        rules={[{ required: true, message: "Currency is required" }]}
                      >
                        <Select
                          showSearch
                          size="large"
                          placeholder="Select Currency"
                          defaultValue="ALL"
                          style={{  width:"40%",
                          
 
                          }}
                          disabled={isManufacturer(authUser)}
                        >
                          {countries
                            ?.filter((country, index) => {
                              if (isManufacturer(authUser)) {
                                return country.currencyCode === "USD";
                              } else {
                                return (
                                  index ===
                                  countries.findIndex((o) => country.currencyCode === o.currencyCode)
                                );
                              }
                            })
                            .map(
                              (option) =>
                                option.currencyCode && (
                                  <Option key={option.id} value={option.currencyCode}>
                                    {option.currencyCode}
                                  </Option>
                                )
                            )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="oceanfreight"
                        noStyle
                        rules={[{ required: false, message: "Price is required" }]}
                      >
                        <Input
                          type="number"
                          defaultValue={0}
                          min={0}
                          placeholder="Enter Price"
                          size="large"
                          style={{  width:"60%" }}
                        />
                      </Form.Item>
                      </div>
                    </Input.Group>
                  </Form.Item>
                   </Col>

                   <Col span={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Price Validity"
                  name="pricevalidity"
                  rules={[
                    {
                      required: true,
                      message: "Price Validity  Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabledDate={(currentDate) => {
                      const pricevalidity =
                        form.getFieldValue("pricevalidity");
                      return (
                        currentDate && currentDate.isBefore(pricevalidity)
                      );
                    }}
                  />
                </Form.Item>
              </Col>


           <Col span={24}>
                          <Form.Item
                            disabled={isEditDisabled}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label={labelComponent("Comments/Addition Details")}
                            name="comments"
                            rules={[
                              {
                                validator: validateTextArea,
                              },
                              {
                                required: true,
                                message: "Requirements is required",
                              },
                            ]}
                          >
                            <TextArea
                              disabled={isEditDisabled}
                              rows={10}
                              placeholder="Kindly Provide Local and destination charges."
                              showCount
                              maxLength={250}
                            />
                          </Form.Item>
                        </Col>


                        <Row className="gx-pt-5">
            <Col span={24}>
              <Form.Item className="gx-text-center">
                <div
                  className="gx-mr-3"
                  style={{
                    justifyContent: isMobile ? "center" : "center",
                    justifyItems:"center",
                    alignItems:"center",
                    display: "flex",
                    flex: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    type="secondary"
                    size="large"
                    style={{ width: 150 }}
                    onClick={() => {
                    history.goBack();
                    }}
                  >
                    Cancel
                  </Button>

                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      style={{ width: 150 }}
                    >
                      Submit
                    </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>

</Row>
</Form>
    </Card>
  )
}

export default Updatesfreightrates

import React from "react";
import EnquiriesList from "./../enquiries/EnquiriesList";
import { useSelector } from "react-redux";
import PromotionBanner from "./banner/banner";
import { isBuyer, isExporter } from "./../../util/helper";
import VerificationBanner from "./setup/VerificationBanner";
import RFQList from "../requestQuotation/RFQList";
import StaticBanner from "./banner/StaticBanner";

const Home = () => {
  const userData = useSelector(({ auth }) => auth?.userData);
  return (
    <>
      {/*<Setup />*/}
      {/* {userData?.status !== "approved" && (
        <VerificationBanner showButton={true} />
      )} */}
      {/* <StaticBanner/> */}
      <PromotionBanner />
      {isBuyer(userData) && <RFQList />}
      {!isBuyer(userData) && <EnquiriesList fromDashboard={true} />}
      
    </>
  );
};

export default Home;

import React, { useCallback, useEffect, useState } from "react";
import { Card, Image, List, Modal, notification } from "antd";
import { isValidElement, safeStringValue } from "util/ApiHelper";
import { useHistory } from "react-router-dom";
import EnquiryStatusTags from "./EnquiriesStatus";
import { Button } from "antd";
import { tabKeys } from "./EnquiryTabs";
import { LeftOutlined } from "@ant-design/icons";
import { isLessThan24Hrs } from "util/helper";
import { EnquiryStatus } from "util/Constant";
import { isArrayNonEmpty, isValidDate } from "../../util/ApiHelper";
import { getNewLine } from "util/helper";
import { useSelector } from "react-redux";
import {
  isCHA,
  isFreightForwarder,
  isManufacturer,
  isShipping,
} from "../../util/helper";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import UnLockedIcon from "../../../src/assets/images/basil_unlock-solid.svg";
import LockedIcon from "../../../src/assets/images/material-symbols_lock (1).png";

import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { fetchStart, fetchSuccess } from "appRedux/actions/Common";
import { useDispatch } from "react-redux";
import { labelComponent } from "../../util/UtilComponents";

const EnquiryDetails = ({ enquriesData, onNext, enquiryId }) => {
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();

  const moment = require("moment");
  const userData = useSelector(({ auth }) => auth?.userData);
  const [viewNotification, setViewNotification] = useState(false);
  console.log("viewNotification",viewNotification)

  const [fileName, setFileName] = useState("");
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pointsToDeduct, setPointsToDeduct] = useState(null);

  const authUser = useSelector(({ auth }) => auth.userData);
    console.log("userData.businessTypes", userData.businessTypes.code);

    const isfrieghtforwader =userData.businessTypes.code==='freight_forwarder';
    const exporter =userData.businessTypes.code==='exporter';
    const manufacturer =userData.businessTypes.code==='manufacturer';
    const manufacturercumexporter =userData.businessTypes.code==='manufacturer_exporter';
    const customhouseagent =userData.businessTypes.code==='custom_house_agent';


  const dispatch = useDispatch();

  const handleCancel = () => {
    history.push("/");
    setIsModalVisible(false);
  };

  const cargoReadinessDate = moment(enquriesData?.cargoReadinessDate).format(
    "DD-MMM-YYYY"
  );

  const fetchEnquiryStatus = useCallback(async (enquiryId) => {
    if (!enquiryId) return;
    dispatch(fetchStart());
    setLoading(true);
    try {
      const uuid = authUser?.uuid;

      const response = await axiosInstance.get(
        `${BASE_API_CONFIG.baseURL}/unlock-enquiries/${enquiryId}`
      );

      const { constants } = response?.data?.data || {};
      const { enquiry } = response?.data?.data || {};

      const enquiryData = response?.data?.data?.enquiry?.find(
        (item) => item.enquiryId === enquiryId && item.uuid === uuid
      );

      const PointsData = response?.data?.data?.constants?.find(
        (item) => item.key === userData?.businessTypes.code
      );
      console.log("responcecheck>>>1Pointsdata", PointsData.value);
      console.log("responcecheck>>>2Pointsdata", response?.data);

      if (enquiryData) {
        setIsUnlocked(enquiryData.status === "unlocked");
      }
      if (PointsData) {
        setPointsToDeduct(PointsData.value);
      } else {
        setIsUnlocked(false);
        setPointsToDeduct(null);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message || "Failed to fetch enquiry status.",
      });
    } finally {
      setLoading(false);
      dispatch(fetchSuccess());
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchEnquiryStatus(enquiryId);
      setLoading(false);
    };
    if (enquiryId) {
      fetchData();
    }
  }, [enquiryId, fetchEnquiryStatus]);

  const handleUnlock = async () => {
    try {
      setLoading(true);

      const unlockData = {
        usersuuid: userData?.usersuuid,
        enquiryId: enquriesData?.id,
        businessTypes: userData?.businessTypes,
      };

      const response = await axiosInstance.post(
        `${BASE_API_CONFIG.baseURL}/unlock-enquiries`,
        {
          uuid: userData?.uuid,
          enquiryId: enquriesData?.id,
          businessTypes: userData?.businessTypes,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.success) {
        setIsUnlocked(true);
        notification.success({
          message: "Unlocked Successfully",
          description: "The CompanyName is now visible, and you can proceed.",
        });
      } else {
        notification.error({
          message: "Unlock Failed",
          description:
            response?.data?.message || "Unable to unlock the content.",
        });
      }
    } catch (error) {
      Modal.confirm({
        title: "Insufficient Points",
        content:
          "You don’t have enough points to unlock. Please purchase the required Points.",
        okText: "Purchase Points",
        cancelText: "Cancel",
        onOk: () => {
          history.push("/my-profile");
        },
        onCancel: () => {
          history.push();
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (enquriesData?.id) {
      fetchEnquiryStatus(enquriesData.id);
    } else {
      console.error("enquiryId is undefined!");
    }
  }, [enquriesData]);

  useEffect(() => {
    if (isArrayNonEmpty(enquriesData?.productImages)) {
      const fileNames = enquriesData?.productImages?.map((image) => {
        return image;
      });
      setFileName(fileNames);
    }
  }, [enquriesData]);

  const displayNotification = useCallback(() => {
    if (!viewNotification) {
      notification.error({
        description:
          "View Profile will be enabled only after Quotation is Sent to Buyer.",
        style: {
          ...(isMobile && { padding: "10px" }),
        },
      });
      setViewNotification(true);
      setTimeout(() => setViewNotification(false), 5000);
    }
  }, [viewNotification, isMobile]);

  const eventHandler = isMobile
    ? { onClick: displayNotification }
    : { onMouseEnter: displayNotification };

  const getStatus = (record) => {
    let status = record?.readReciept[0]?.status;
    if (!status) {
      status = isLessThan24Hrs(record?.createdAt)
        ? EnquiryStatus.NEW_ENQUIRY
        : EnquiryStatus.AWAITING_FOR_QUOTATION;
    }
  };
  
  const data = [
    {
      label: "Posted Date",
      value: moment(enquriesData?.createdAt).format("DD-MMM-YYYY"),
    },
    {
      label: "Company Name",
      value: (
        <>
          <div
            style={{
              position: "relative",
              filter: isUnlocked ? "none" : "blur(2.3px)",
            }}
          >
               {enquriesData?.user?.companyName ||isUnlocked } &nbsp;&nbsp;
              {  enquriesData?.quotations[1]?.status ===EnquiryStatus.ORDER_CONFIRMED || enquriesData?.quotations[1]?.status ===EnquiryStatus.ORDER_PLACED  || isUnlocked

              &&  (!isfrieghtforwader || isUnlocked )   //already correct
              && (!exporter || enquriesData?.quotations[0]?.status === EnquiryStatus.QUOTATION_SENT)
              && (!manufacturer || enquriesData?.quotations[0]?.status === EnquiryStatus.QUOTATION_SENT)
              && (!manufacturercumexporter || enquriesData?.quotations[0]?.status === EnquiryStatus.QUOTATION_SENT)
              && (!customhouseagent || enquriesData?.quotations[0]?.status === EnquiryStatus.QUOTATION_SENT)
              ? ( 
              <span
                onClick={() => navigateBusinessCard(enquriesData?.user?.id)}
                className={"myexim-link "}
              >
                View Profile
              </span>
            ) : (
              <span
                style={{
                  color: "#909090",
                  cursor: "not-allowed",
                  maxWidth: "80px",
                }}
                {...eventHandler}
                className={"myexim-link "}
              >
                View Profile
              </span>
            )}
          </div>
          {!isUnlocked && (
            <>
              <Button
                style={{
                  background: "#84D073",
                  borderRadius: "16.15px",
                  height: "24px",
                  width: "270px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleUnlock}
                loading={loading}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      src={UnLockedIcon}
                      alt="Locked Icon"
                      style={{ width: "16px", marginRight: "16px" }}
                    />
                  </div>
                  <div style={{color: "white" }}>unlock to view the company name</div>
                </div>
              </Button>
              <p style={{ color: "#434E5B" }}>
                {`Use ${pointsToDeduct || ""} point${
                  pointsToDeduct !== 1 ? "s" : ""
                } to unlock`}
              </p>
            </>
          )}
        </>
      ),
    },
    ...(!isCHA(userData) && !isShipping(userData) && !isFreightForwarder(userData))
      ? [
          {
            label: "Category",
            value: enquriesData?.categories?.name,
          },
        ]
      : [],

      ...(!isCHA(userData) && !isShipping(userData) && !isFreightForwarder(userData))
      ? [
          {
            label: "HS Code / Item Description",
            value: enquriesData?.hscode
              ? `${enquriesData.hscode.code} - ${enquriesData.hscode.description}`
              : "N/A",
          },
        ]
      : [],
    {
      label: "Product Name",
      value: enquriesData?.productName,
    },
    {
      label: "Quantity",
      value: `${
        enquriesData?.qty
          ? enquriesData?.qty + " " + enquriesData?.units
          : enquriesData?.noOfContainers +
            " " +
            (enquriesData?.noOfContainers > 1 ? "Containers" : "Container")
      }`,
    },
    ...(isManufacturer(userData)
      ? [
          {
            label: "Delivery Terms",
            value: `${safeStringValue(
              enquriesData?.paymentTerms
            )} - ${safeStringValue(enquriesData?.locationForDelivery)}`,
          },
        ]
      : []),
    ...(enquriesData?.netWeightPerContainer
      ? [
          {
            label: "Net Weight Per Container",
            value: `${enquriesData?.netWeightPerContainer} Killograms`,
          },
        ]
      : []),
    {
      label: "Requirement Details",
      value: getNewLine(enquriesData?.description),
    },
    {
      label: "Source Country",
      value: enquriesData?.sourceCountry,
    },
    ...(isCHA(userData) || isShipping(userData) || isFreightForwarder(userData)
      ? [{ label: "Container Type", value: enquriesData?.containerType }]
      : []),

    ...(isCHA(userData) || isShipping(userData) || isFreightForwarder(userData)
      ? [
          {
            label: "Source Port",
            value: enquriesData?.sourcePort,
          },
        ]
      : []),
    {
      label: "Destination Country",
      value: enquriesData?.destinationCountry,
    },
    {
      label: "Destination Port",
      value: enquriesData?.destinationPort,
    },
    {
      label: "Status",
      value: getStatus(enquriesData),
    },
    ...(enquriesData?.currency
    ? [
        {
          label: "Currency",
          value: enquriesData?.currency
        },
      ]
    : []),
    ...(enquriesData?.rateindication
    ? [
        {
          label: "Rate Indication",
          value: enquriesData?.rateindication
        },
      ]
    : []),
    ...(isValidDate(cargoReadinessDate)
      ? [
          {
            label: "Cargo Readiness Date",
            value: moment(enquriesData?.cargoReadinessDate).format(
              "DD-MMM-YYYY"
            ),
          },
        ]
      : []),
      
      ...(isFreightForwarder(userData)
      ? [
      {
        label:labelComponent('Note:'),
        value:"You can still send the quotation without unlocking the enquiry. We will coordinate with the exporter and get back to you."
      }
    ]
    : []),

    
    
  ];

  const navigateBusinessCard = (id) => {
    if (id) {
      history.push(`/user-profile?id=${id}`);
    }
  };

  return (
    isValidElement(enquriesData) && (
      <Card className="myexim-detail-card">
        <div className="myexim-table-title">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          Enquiry Details
        </div>
        {!isUnlocked && (
          <>
            <div
              style={{
                alignContent: "right",
                alignItems: "right",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{
                  background: "#F8900E",
                  borderRadius: "16.15px",
                  height: "24px",
                  width: "95px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                loading={loading}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      src={LockedIcon}
                      alt="Locked Icon"
                      style={{ width: "16px", marginRight: "16px" }}
                    />
                  </div>
                  <div style={{ color: "white" }}>locked</div>
                </div>
              </Button>
            </div>
          </>
        )}
        <List
          className="gx-mt-4"
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) =>
            item?.value && (
              <List.Item className="myexim-details-list">
                <List.Item.Meta
                  title={<div>{item.label}</div>}
                  description={<div>{item.value}</div>}
                />
              </List.Item>
            )
          }
        />
        {fileName?.length > 0 && (
          <div className="attachments gx-mt-3">
            <div className="sub-header">Attachments</div>
            <div>
              Please find the below attachment as a reference to the requirement
            </div>
            <div className="gx-pt-4">
              {fileName?.map((fileName, index) => (
                <div key={index} className="gx-pb-3">
                  <Image
                    className="gx-rounded-lg"
                    width={isMobile ? "100%" : 150}
                    height={isMobile ? "100%" : 150}
                    src={fileName?.src}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div
          style={{
            justifyContent: isMobile ? "center" : "end",
            display: "flex",
            flex: 1,
            flexWrap: "wrap",
          }}
          className="gx-pt-4"
        >
          <Button
            type="secondary"
            size="large"
            className="submit-btn"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            className="submit-btn"
            disabled={!isUnlocked && authUser?.businessTypes?.code !== "freight_forwarder"}
            onClick={() => {
              onNext(tabKeys.QUOTATION_TAB);
            }}
          >
            { isArrayNonEmpty(enquriesData.quotations) &&
                enquriesData?.quotations?.[0]?.senderId === userData?.id
                ? "View Quotation"
              : "Prepare Quotation"}
          </Button>
        </div>
      </Card>
    )
  );
};

export default EnquiryDetails;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Table, Button, Modal, Form, Input, Select, Dropdown, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../util/helper";
import { isArrayNonEmpty } from "../../util/ApiHelper";
import ListEmptyComponent from "../../components/ListEmptyComponent/ListEmptyComponent";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import Meta from "antd/lib/card/Meta";
import { getFreightRatesList } from "../../appRedux/actions/updatefreightrateAction";
import { CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FileTextOutlined, MoreOutlined } from "@ant-design/icons";
import { ItemDetailMobile } from "../../components/ItemDetailMobile";

const { Option } = Select;

const UpdateFreightRateList = () => {
  const dispatch = useDispatch();
  const { isMobile } = useGetBreakpoints();
  const history = useHistory();
  const [form] = Form.useForm();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRate, setSelectedRate] = useState(null);

  const freightRatesList = useSelector((state) => state.freightRates.freightRatesList);
  const { loading } = useSelector(({ common }) => common);
  const authUser = useSelector(({ auth }) => auth.userData);
    console.log("authUser",authUser.id)

    const userFreightRates = freightRatesList.filter(item => item.userid === authUser?.id);


  const sortedFreightRates = userFreightRates
    .slice() 
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

   const [currentPage, setCurrentPage] = useState(1);
   const [pageSize, setPageSize] = useState(5);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return userFreightRates.slice(startIndex, endIndex);
  }, [userFreightRates, currentPage, pageSize]);


    const ids = freightRatesList.map(item => item.userid);
    console.log("ids",ids)
  console.log("freightRatesList", freightRatesList); 

  useEffect(() => {
    dispatch(getFreightRatesList());
  }, [dispatch]);

  const handleCancelModal = () => {
    setShowUpdateModal(false);
    setSelectedRate(null);
    form.resetFields();
  };

  const showUpdateModalForRate = useCallback((rate) => {
    setShowUpdateModal(true);
    setSelectedRate(rate);
    form.setFieldsValue({
      rate: rate.rate,
      currency: rate.currency,
    });
  }, [form]);


const id =authUser.id;
const handleViewDetails = useCallback((record) => {
      history.push({
        pathname: `/freight-market-watch-Details/${id}`,
        state: { userFreightRates: record }, 
      });
    }, [history]);
  

  const columns = useMemo(
    () => [
      {
            title: "Posted On",
            key: "createdAt",
            render: (record) => <div>{formatDate(record.createdAt)}</div>,
      },
      {
            title: "Loading Port",
            key: "loadingPort",
            render: (record) => <div>{record.loadingPort}</div>,
      },
      {
        title: "Destination Country",
        key: "destinationCountry",
        render: (record) => <div>{record.destinationCountry}</div>,
      },
      {
        title: "Destination Port",
        key: "destinationPort",
        render: (record) => <div>{record.destinationPort}</div>,
      },
      {
        title: "Ocean Freight",
        key: "oceanfreight",
         render: (_, record) => {
                const formattedFreight = new Intl.NumberFormat('en-IN', {
                  style: 'decimal',
                }).format(record.oceanfreight);
                return `${record.currency} ${formattedFreight}`;
              },
      },
      {
        title: "Price Validity",
        key: "pricevalidity",
        render: (record) => <div>{formatDate(record.pricevalidity)}</div>,
    },
      {
        title: "Comments",
        key: "comments",
        render: (record) => <div>{record.comments}</div>,
      },
      {
        title: "Actions",
        key: "actions",
        render: (record) => (
            <div
              className="myexim-action-icon"
              onClick={(e) => e.stopPropagation()}
            >
              <Dropdown
                menu={{
                  items: [
                    {
                      label: "View  Details",
                      key: "1",
                      icon: <EyeOutlined />,
                      onClick: () => {
                        handleViewDetails(record);
                      },
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <MoreOutlined />
              </Dropdown>
            </div>
          ),
      },
    ],
    [showUpdateModalForRate]
  );

  const renderMobileView = useCallback(
    () =>
      sortedFreightRates.map((freightRatesList,index) => (
        <React.Fragment key={freightRatesList.key}>
          <div style={{ display: "flex", alignItems: "center",marginBottom: "16px",marginTop:"-5px",marginLeft:"1px" }}>
            <div style={{ flex: 1 }} onClick={() => handleViewDetails(freightRatesList)}>
              <div style={{ fontSize: "12px", color: "#666", marginBottom: "8px" }}>
                Posted On {formatDate(freightRatesList.createdAt)}
              </div>
                <div
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "4px",
                  color: "#333",
                }}
              >
                {freightRatesList.destinationCountry}
              </div>
  
              <div style={{ fontSize: "14px", color: "#666", marginBottom: "14px" }}>
                {freightRatesList.destinationPort}
              </div>
            </div>
              <div style={{ marginRight: "30px" ,fontWeight: "bold"}}>
            {freightRatesList.oceanfreight} {freightRatesList.currency}
        
            </div>
          </div>
          {index !== sortedFreightRates.length - 1 && <hr style={{ margin: "16px 0" }} />}
          </React.Fragment>
                )),
    [paginatedData, showUpdateModalForRate]
  );

  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setCurrentPage(1);
    }
  };

  const renderContent = useCallback(
    (data, count) => {
      return isMobile ? (
        <div>
          <div style={{ padding: "16px" }}>
            {renderMobileView()}
          </div>
  
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={paginatedData.length}
            onChange={handlePageChange}
            showSizeChanger={true}
            showQuickJumper={false}
            simple
            style={{ marginTop: 16, textAlign: "center" }}
          />
        </div>
      ) : (
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          className="gx-table-responsive rfq-table myexim-table"
          columns={columns}
          onRow={(record) => ({
            onClick: () => handleViewDetails(record),
          })}
          dataSource={data}
          pagination={{
            position: ["bottomRight"],
            showSizeChanger: true,
            pageSize: 5,
            total: count,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      );
    },
    [columns, isMobile, showUpdateModalForRate]
  );

  return (
    <>
      <Card
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="myexim-table-title">Freight Rates</div>
            <Button
              type="primary"
              onClick={() => history.push("/updatefreightrate")}
              style={{ marginLeft: 15 }}
            >
              + Create New Freight Rate
            </Button>
          </div>
        }
        style={{ width: "100%" }}
      >

          {isArrayNonEmpty(sortedFreightRates) ? (
           renderContent(sortedFreightRates, sortedFreightRates.length)
           
      ) : 
      (
        !loading && (
          <ListEmptyComponent
            message="No Freight Rates Created Yet, Try it now"
            buttonText="Create New Freight Rate"
            onButtonClick={() => history.push("/updatefreightrate")}
          />
        )
      )}     
      </Card>
      
      
    </>
  );
};

export default UpdateFreightRateList;
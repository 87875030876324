import React from 'react';
import { Card, Descriptions, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { useGetBreakpoints } from '../../hooks/useGetBreakpoints';

const UpdatefreightrateDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const { isMobile } = useGetBreakpoints();

  const freightRate = state?.freightRate;
  console.log("freightRate",freightRate)
  const allFreightRates =state?.allFreightRates;
  console.log("allFreightRatescheck00",allFreightRates)

  

  const userFreightRates = state?.userFreightRates ||allFreightRates;
  console.log("userFreightRates",userFreightRates)

  if (!freightRate) {
    return (
        <Card
        className="myexim-detail-card"
        title={
          <div className="myexim-table-title gx-text-capitalize">
            <LeftOutlined
              className={'back-icon'}
              onClick={() => history.goBack()}
            />
            Freight Rate Details
          </div>
        }
      >
        <Descriptions column={1} bordered >
          <Descriptions.Item label="Posted Date" labelStyle={{fontWeight:"Bold"}}>
            {userFreightRates.createdAt ? 
              format(new Date(userFreightRates.createdAt), 'dd-MMM-yyyy') : '-'}
          </Descriptions.Item>

          <Descriptions.Item label="Loading Country" labelStyle={{fontWeight:"Bold"}}>
            {userFreightRates.loadingCountry}
          </Descriptions.Item>

          <Descriptions.Item label="Loading Port" labelStyle={{fontWeight:"Bold"}}>
            {userFreightRates.loadingPort}
          </Descriptions.Item>
          
          <Descriptions.Item label="Destination Country" labelStyle={{fontWeight:"Bold"}}>
            {userFreightRates.destinationCountry}
          </Descriptions.Item>
  
          <Descriptions.Item label="Destination Port" labelStyle={{fontWeight:"Bold"}}>
            {userFreightRates.destinationPort}
          </Descriptions.Item>
  
          <Descriptions.Item label="Freight Rate" labelStyle={{fontWeight:"Bold"}}>
            {userFreightRates.currency} {new Intl.NumberFormat('en-IN').format(userFreightRates.oceanfreight)}
          </Descriptions.Item>

          <Descriptions.Item label="Price Validity" labelStyle={{fontWeight:"Bold"}}>
            {userFreightRates.pricevalidity ? 
              format(new Date(userFreightRates.pricevalidity), 'dd-MMM-yyyy') : '-'}
          </Descriptions.Item>
  
          <Descriptions.Item label="Comments" labelStyle={{fontWeight:"Bold"}}>
            {userFreightRates.comments || '-'}
          </Descriptions.Item>
  
          <Descriptions.Item label="Freight Forwarder Name " labelStyle={{fontWeight:"Bold"}}>
            {userFreightRates.username}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Freight Forwarder Id" labelStyle={{fontWeight:"Bold"}}>
            {userFreightRates.userid}
          </Descriptions.Item> */}
        </Descriptions>
      <hr></hr>
      <div
            style={{
              justifyContent: isMobile ? "center" : "end",
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
            }}
            className="gx-pt-4"
          >
      <Button
                    type="secondary"
                    size="large"
                    className="submit-btn"
                    style={{alignItems:"center",alignContent:"center"}}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                  </div>
      </Card>
    );
  }

  return (
    <Card
      className="myexim-detail-card"
      title={
        <div className="myexim-table-title gx-text-capitalize">
          <LeftOutlined
            className={'back-icon'}
            onClick={() => history.goBack()}
          />
          Freight Rate Details
        </div>
      }
    >
      <Descriptions column={1} bordered >
        <Descriptions.Item label="Posted Date" labelStyle={{fontWeight:"Bold"}}>
          {freightRate.createdAt ? 
            format(new Date(freightRate.createdAt), 'dd-MMM-yyyy') : '-'}
        </Descriptions.Item>

        <Descriptions.Item label="Loading Country" labelStyle={{fontWeight:"Bold"}}>
          {freightRate.loadingCountry}
        </Descriptions.Item>
        <Descriptions.Item label="Loading Port" labelStyle={{fontWeight:"Bold"}}>
          {freightRate.loadingPort}
        </Descriptions.Item>
        
        <Descriptions.Item label="Destination Country" labelStyle={{fontWeight:"Bold"}}>
          {freightRate.destinationCountry}
        </Descriptions.Item>

        <Descriptions.Item label="Destination Port" labelStyle={{fontWeight:"Bold"}}>
          {freightRate.destinationPort}
        </Descriptions.Item>

        <Descriptions.Item label="Freight Rate" labelStyle={{fontWeight:"Bold"}}>
          {freightRate.currency} {new Intl.NumberFormat('en-IN').format(freightRate.oceanfreight)}
        </Descriptions.Item>

        <Descriptions.Item label="Price Validity" labelStyle={{fontWeight:"Bold"}}>
            {freightRate.pricevalidity ? 
              format(new Date(freightRate.pricevalidity), 'dd-MMM-yyyy') : '-'}
          </Descriptions.Item>

        <Descriptions.Item label="Comments" labelStyle={{fontWeight:"Bold"}}>
          {freightRate.comments || '-'}
        </Descriptions.Item>

        <Descriptions.Item label="Freight Forwarder Name " labelStyle={{fontWeight:"Bold"}}>
          {freightRate.username}
        </Descriptions.Item>
        {/* <Descriptions.Item label="Freight Forwarder Id" labelStyle={{fontWeight:"Bold"}}>
          {freightRate.userid}
        </Descriptions.Item> */}
      </Descriptions>
    <hr></hr>
    <div
          style={{
            justifyContent: isMobile ? "center" : "end",
            display: "flex",
            flex: 1,
            flexWrap: "wrap",
          }}
          className="gx-pt-4"
        >
    <Button
                  type="secondary"
                  size="large"
                  className="submit-btn"
                  style={{alignItems:"center",alignContent:"center"}}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Button>
                </div>
    </Card>
   
  );
};

export default UpdatefreightrateDetails;
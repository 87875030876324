import { InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ProfileAvatar from "../../routes/myProfile/ProfileAvatar";
import { useDispatch, useSelector } from "react-redux";
import {
  getLabel,
  isArrayNonEmpty,
  isValidElement,
  isValidString,
} from "../../util/ApiHelper";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import Upload from "components/Upload";
import { getBusinessType } from "../../util/helper";
import TextArea from "antd/lib/input/TextArea";
import { useForm } from "antd/lib/form/Form";
import { calculateProfilePercent } from "../../routes/home/setup/VerificationBanner";
import {
  fetchStart,
  fetchSuccess,
  updateAuthUser,
} from "../../appRedux/actions";
import { CustomSuccessMessage } from "../../util/CustomMessage";
import { labelComponent } from "../../util/UtilComponents";
import { countries } from "../../constants/country";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  MapDocumentData,
  ProfileDocuments,
} from "../../routes/myProfile/ProfileUtils";

const MyUsersEditProfile = () => {
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const { Option } = Select;
  const history = useHistory();
  const [form] = useForm();
  const dispatch = useDispatch();
  const ports = useSelector(({ common }) => common.ports);
  const [selectedPorts, setSelectedPorts] = useState([]);
  const [portsList, setPortsList] = useState([]);
  const authUser = useSelector(({ auth }) => auth.userData);
  const [userById, setUserById] = useState(null);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (userById) {
      const attachmentsData = userById?.attachments || [];
      const profileDocuments =
        ProfileDocuments[getBusinessType(userById)] || [];
      const mappedData =
        MapDocumentData(attachmentsData, profileDocuments) || [];
      setAttachments([...mappedData]);
    }
  }, [userById]);

  const [profileImage, setProfileImage] = useState(
    isValidString(userById?.companyLogo) ? userById?.companyLogo : ""
  );

  const removeProfile = async () => {
    setProfileImage("");
  };

  useEffect(() => {
    if (isArrayNonEmpty(ports)) {
      const filteredPorts = ports?.filter((item) => item?.countryId !== 105);
      setPortsList(filteredPorts);
    }
  }, [ports]);

  const handleSelect = (value) => {
    if (selectedPorts.length >= 5) {
      message.warning("You can select a maximum of 5 ports!");
      return;
    }
    const updatedPorts = [...selectedPorts, value];
    setSelectedPorts(updatedPorts);
    form.setFieldsValue({ destinationPorts: updatedPorts });
  };

  const handleDeselect = (value) => {
    const updatedPorts = selectedPorts.filter((port) => port !== value);
    setSelectedPorts(updatedPorts);
    form.setFieldsValue({ destinationPorts: updatedPorts });
  };

  const getuserById = async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const userById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/users/${id}`
        );
        setUserById(userById?.data?.data);
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };

  useEffect(() => {
    if (isValidString(id)) {
      getuserById(id);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (isValidElement(userById)) {
      const portArrays = userById?.port ? JSON.parse(userById.port) : [];
      setSelectedPorts(portArrays);

      form.setFieldsValue({
        firstName: userById?.name,
        companyName: userById?.companyName,
        phoneNumber: userById?.mobile,
        email: userById?.email,
        city: userById?.city,
        country: userById?.country,
        description: userById?.companyDescription,
        address: userById?.address,
        gstNumber: userById?.gstNumber,
        destinationPorts: portArrays,
      });
    }
  }, [form, userById]);

  const onFinish = async (values) => {
    let data = {
      name: values?.firstName,
      companyName: values?.companyName,
      mobile: values?.phoneNumber,
      email: values?.emailId,
      city: values?.city,
      address: values?.address,
      country: values?.country,
      companyDescription: values?.description,
      companyLogo: profileImage,
      attachments: attachments,
      businessTypes: {
        code: userById?.businessTypes?.code,
      },
      gstNumber: values?.gstNumber,
      port: values?.destinationPorts,
    };

    data.profilePercent = calculateProfilePercent(data)?.percent;
    await axiosInstance.put(`${BASE_API_CONFIG.baseURL}/users/${id}`, data);

    const userResponse = await axiosInstance.get(
      `${BASE_API_CONFIG.baseURL}/users/${id}`
    );
    localStorage.setItem("userData", JSON.stringify(userResponse?.data?.data));
    dispatch(updateAuthUser(userResponse?.data?.data));
    history.goBack();
    message.success({
      duration: 10,
      icon: <></>,
      content: (
        <CustomSuccessMessage
          title={`Profile Updated Successfully.`}
          message={`The updates made to your profile will reflect immediately.`}
        />
      ),
      className: "custom-success-message",
    });
  };

  const fileUpload = async (e) => {
    if (e.target.files.length) {
      const fmData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      fmData.append("image", e.target.files[0]);
      try {
        let response = await axiosInstance.post(
          `${BASE_API_CONFIG.baseURL}/file-manager/single`,
          fmData,
          config
        );
        let src = `${response?.data?.response?.url}`;
        setProfileImage(src);
      } catch (e) {
        message.error("file upload failed.");
      }
    }
  };

  const onUpload = (data, key) => {
    setAttachments((previousValue) => {
      return previousValue.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            src: data.src,
            originalName: data.originalName,
          };
        }
        return item;
      });
    });
  };

  const onRemove = (data, key) => {
    setAttachments((previousValue) => {
      return previousValue.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            src: undefined,
            originalName: undefined,
          };
        }
        return item;
      });
    });
  };

  return (
    <Card className="myexim-detail-card">
      <div className="myexim-table-title gx-text-capitalize">
        <LeftOutlined
          className={"back-icon"}
          size="large"
          onClick={() => {
            history.goBack();
          }}
        />{" "}
        Edit Profile
      </div>
      <ProfileAvatar
        authUser={authUser}
        removeProfile={removeProfile}
        fileUpload={fileUpload}
        profileImage={profileImage}
      />
      <Form form={form} onFinish={onFinish} scrollToFirstError={true}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input
                placeholder="Enter name"
                size={"large"}
                showCount
                maxLength={30}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Company Name"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Company Name is required",
                },
              ]}
            >
              <Input
                placeholder="Enter Company Name"
                size={"large"}
                showCount
                maxLength={100}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Phone Number is required",
                },
              ]}
            >
              <Input
                disabled={true}
                placeholder="Enter Phone Number"
                size={"large"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input disabled={true} placeholder="Enter Email" size={"large"} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "City is required",
                },
              ]}
            >
              <Input placeholder="Enter City" size={"large"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  message: "Country is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                name="country"
                placeholder="Country*"
              >
                {countries.map((country) => (
                  <Option key={country.code} value={country.name}>
                    {country.name} ({country.dial_code})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="GST Number"
              name="gstNumber"
              rules={[
                {
                  required: true,
                  message: "GST Number is required",
                },
              ]}
            >
              <Input placeholder="Enter GST Number" size={"large"} />
            </Form.Item>
          </Col>
          {userById?.port !== null && (
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label={labelComponent(
                  "Destination Ports",
                  `You can select maximum 5 destination ports`
                )}
                name="destinationPorts"
                rules={[
                  {
                    required: true,
                    message: "Destination Ports are required",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  size="large"
                  placeholder="Select Destination Ports"
                  value={selectedPorts}
                  onSelect={handleSelect}
                  onDeselect={handleDeselect}
                  maxTagCount={5}
                >
                  {portsList?.map((item) => (
                    <Option
                      key={item?.id}
                      value={`${item?.port} (${item?.code})`}
                      disabled={
                        selectedPorts?.length === 5 &&
                        !selectedPorts
                          ?.map((port) => port)
                          ?.includes(`${item?.port} (${item?.code})`)
                      }
                    >
                      {`${item?.port} (${item?.code})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Address is required",
                },
              ]}
            >
              <TextArea
                rows={5}
                placeholder={"Enter Your Company Address"}
                showCount
                maxLength={100}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="About"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Description is required",
                },
              ]}
            >
              <TextArea
                rows={5}
                placeholder={"Enter Description About Your Company"}
                showCount
                maxLength={100}
              />
            </Form.Item>
          </Col>

          {attachments.map((file, index) => (
            <Col span={12}>
              <div className="sub-header">{file.desc}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Upload
                  index={index}
                  src={file.src}
                  id={index}
                  label={
                    isValidString(file?.desc) ? file?.desc : getLabel(index)
                  }
                  originalName={file.originalName}
                  onUpload={(data) => onUpload(data, file.key)}
                  onRemove={(data) => onRemove(data, file.key)}
                />
              </div>
            </Col>
          ))}
          <Col>
            <div className="upload-tips gx-mt-4">
              <div className="header" style={{ display: "flex" }}>
                <InfoCircleOutlined />
                &nbsp; Tips{" "}
              </div>
              <div className="desc">
                <Badge
                  status="default"
                  text="Supported file types: .jpg, .jpeg, .png , .pdf"
                />
                <br />
                <Badge status="default" text="File must not more than 5MB" />
                <br />
                <Badge
                  status="default"
                  text="Make sure you have good resolution"
                />
                <br />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: 30 }}>
            <Form.Item className="gx-text-center">
              <Button
                type="secondary"
                size="large"
                style={{ width: 150 }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: 150 }}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default MyUsersEditProfile;

import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Input, Select, Checkbox, message } from "antd";
import { Link } from "react-router-dom";
import { countries } from "../constants/country";
import { useDispatch } from "react-redux";
import {
  getBusinessTypeOption,
  createUser,
} from "./../appRedux/actions/authAction";
import { useSelector } from "react-redux";
import { labelComponent } from "./../util/UtilComponents";
import { getPorts } from "../appRedux/actions/Common";
import SidebarLogo from "./Sidebar/SidebarLogo";
import { FORM_BUSINESS_TYPE, FORM_BUSINESS_TYPE_FOR_PORT } from "../util/Constant";
import { isArrayNonEmpty ,isValidElement} from "../util/ApiHelper";


const { Option } = Select;

const FormItem = Form.Item;

const SignUp = () => {
  const dispatch = useDispatch();
  const loading = useSelector(({ common }) => common.loading);
  const getBusinessType = useSelector((state) => state.auth.businessTypes);
  const ports = useSelector(({ common }) => common.ports);

  const [selectedCountry, selectCountry] = useState("+00");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [businessTypes, setBusinessTypes] = useState(getBusinessType ?? []);

  const [portsList, setPortsList] = useState(ports ?? []);
  const [indianPorts, setIndianPorts] = useState(ports ?? []);
  const [selectedPorts, setSelectedPorts] = useState([]);

  const [form] = Form.useForm();
  const formRef = form;

  useEffect(() => {
    dispatch(getBusinessTypeOption());
    dispatch(getPorts());
  }, [dispatch]);

  useEffect(() => {
    if (isArrayNonEmpty(ports)) {
      const filteredIndianPorts = Array.from(
        new Map(
          ports
            .filter((item) => item?.countryId === 105)
            .map((item) => [item.port, item])
        ).values()
      );
      setIndianPorts(filteredIndianPorts);
    }
  }, [ports]);

  useEffect(() => {
    if (isArrayNonEmpty(ports)) {
      const filteredPorts = Array.from(
        new Map(
          ports
            .filter((item) => item?.countryId !== 105)
            .map((item) => [item.port, item])
        ).values()
      );
      setPortsList(filteredPorts);
    }
  }, [ports]);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    console.log("valuescheck---1",values)
    const businessTypeId = getBusinessType[values?.businessType].id;
    const port = ports.filter((port) => values?.portId?.includes(port?.id));
    const portNames = port.map((name) => `${name.port}(${name.code})`);
    const loadingPort = values?.loadingPort;

    dispatch(
      createUser(
        values?.name,
        values?.email,
        values?.company,
        selectedCountry.dial_code + values.mobile,
        businessTypeId,
        selectedCountry?.name,
        selectedCountry?.code,
        values?.gstNumber,
        loadingPort,
        // values?.portId,
        portNames,
      )
      
    );
    console.log("valuescheck---2",loadingPort)


  };

  const businessType = Form.useWatch("businessType", form);

  const handleSelect = (value) => {
    if (selectedPorts.length >= 5) {
      message.warning("You can select a maximum of 5 ports!");
      return;
    }
    setSelectedPorts([...selectedPorts, value]);
  };

  const handleDeselect = (value) => {
    setSelectedPorts(selectedPorts.filter((port) => port !== value));
  };

  const isGstApplicable =
    businessType === FORM_BUSINESS_TYPE.exporter ||
    businessType === FORM_BUSINESS_TYPE.manufacturer ||
    businessType === FORM_BUSINESS_TYPE.manufacturer_exporter ||
    businessType === FORM_BUSINESS_TYPE.custom_house_agent ||
    businessType === FORM_BUSINESS_TYPE.commission_agent ||
    businessType === FORM_BUSINESS_TYPE.freight_forwarder ||
    businessType === FORM_BUSINESS_TYPE.shipping_line;

    const isHandlePortApplicable = 
    businessType === FORM_BUSINESS_TYPE_FOR_PORT.exporter ||
    businessType === FORM_BUSINESS_TYPE_FOR_PORT.manufacturer_exporter;


  return (
    <>
      <div
        className="gx-login-container"
        style={{ marginTop: "auto", paddingTop: 0 }}
      >
        <div style={{ width: "100%", paddingBottom: "40px" }}>
          <SidebarLogo />
        </div>
        <div className="gx-login-content">
          <div className="gx-mb-4">
            <div className="gx-login-header">Create Account</div>
            <p className="gx-login-description">
              Already have an account? &nbsp;{" "}
              <Link className="myexim-link" to="/signin">
                Login
              </Link>
            </p>
          </div>
          <Form
            form={form}
            initialValues={{ remember: true }}
            name="signup"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <FormItem
              rules={[{ required: true, message: "Please input your Name!" }]}
              name="name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent("Name", null)}
            >
              <Input maxLength={75} size="large" placeholder="Name" />
            </FormItem>
            <FormItem
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent("Company Name", null)}
              rules={[
                { required: true, message: "Please input your Company Name!" },
              ]}
              name="company"
            >
              <Input maxLength={75} size="large" placeholder="Company" />
            </FormItem>
            <FormItem
              rules={[
                { required: true, message: "Please input your E-mail!" },
                {
                  type: "email",
                  message: "The input is not a valid E-mail!",
                },
              ]}
              name="email"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent("Email", null)}
            >
              <Input
                maxLength={75}
                size="large"
                placeholder="Email"
                type="email"
              />
            </FormItem>
            <FormItem
              rules={[
                { required: true, message: "Please input your Country!" },
              ]}
              name="country"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent("Country", null)}
            >
              <Select
                showSearch
                size="large"
                name="country"
                placeholder="Country"
                onChange={(value) => {
                  const country = countries.find(
                    (country) => country?.name === value
                  );
                  selectCountry(country);
                  form.resetFields(["businessType"]);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {countries?.map((country) => (
                  <Option key={country.code} value={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              name="mobile"
              placeholder="Mobile Number"
              rules={[
                { required: true, message: "Please input your Mobile Number!" },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent("Mobile Number", null)}
            >
              <Input
                maxLength={10}
                size="large"
                placeholder={"Mobile"}
                prefix={
                  selectedCountry?.dial_code ? (
                    <div
                      style={{
                        borderRight: "1px solid #d3d3d3",
                        paddingRight: 5,
                      }}
                    >
                      {selectedCountry?.dial_code}
                    </div>
                  ) : (
                    ""
                  )
                }
              />
            </FormItem>
            <FormItem
              hidden={selectedCountry === "+00"}
              rules={[
                {
                  required: true,
                  message: "Please input your Nature of Business!",
                },
              ]}
              name="businessType"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent("Nature Of Business", null)}
            >
              <Select
                showSearch
                size="large"
                name="businessType"
                placeholder="Nature of Business"
              >
                {getBusinessType?.map((item, index) => {
                  if (form.getFieldValue("country") == null || form.getFieldValue("country") === "India") {
                    if (item?.id != 1) {
                      return <Option key={index}>{item.name}</Option>;
                    }
                  } else if (item?.id == 1) {
                    return <Option key={index}>{item.name}</Option>;
                  }
                })}
              </Select>
            </FormItem>
            {isGstApplicable && (
              <FormItem
                rules={[
                  { required: true, message: "Please input your GST No" },
                  {
                    pattern:
                      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
                    message: "The input is not a valid GST Number",
                  },
                ]}
                name="gstNumber"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label={labelComponent("GST Number", null)}
              >
                <Input maxLength={15} size="large" placeholder="GST Number" />
              </FormItem>
            )}

            {isHandlePortApplicable && (
               <Form.Item
                                 labelCol={{ span: 24 }}
                                 wrapperCol={{ span: 24 }}
                                 label={labelComponent(
                                   "Loading Port",
                                   "Select the Loading Port where Goods to be loaded"
                                 )}
                                 name="loadingPort"
                                 rules={[
                                   {
                                     required: true,
                                     message: "Loading Port is required",
                                   },
                                 ]}
                               >
                                 <Select
                                   showSearch
                                   virtual={false}
                                   size="large"
                                   placeholder="Select Loading Port"
                                   filterOption={(input, option) =>
                                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                   }
                                   getPopupContainer={(trigger) => trigger.parentNode}
                                 >
                                   {isValidElement(indianPorts)
                                     ? indianPorts?.map((option) => (
                                         <Option key={option.port} value={option.port}>
                                           {`${option.port} (${option.code})`}
                                         </Option>
                                       ))
                                     : ports?.map((option) => (
                                         <Option key={option.port} value={option.port}>
                                           {`${option.port} (${option.code})`}
                                         </Option>
                                       ))}
                                 </Select>
                               </Form.Item>
              )}
            {isHandlePortApplicable && (
              <FormItem
                rules={[
                  {
                    required: true,
                    message: "Please input your port!",
                  },
                ]}
                name="portId"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label={labelComponent("Select Destination Ports", `You can select maximum 5 destination ports`)}
              >
                <Select
                  mode="multiple"
                  showSearch
                  size="large"
                  name="port"
                  placeholder="Please select Destination ports"
                  value={selectedPorts}
                  onSelect={handleSelect}
                  onDeselect={handleDeselect}
                  maxTagCount={5}
                  filterOption={(inputValue, option) => {
                    const item = portsList.find((port) => port.id === option.value);
                    return (
                      item.port.toLowerCase().includes(inputValue.toLowerCase()) ||
                      item.code.toLowerCase().includes(inputValue.toLowerCase())
                    );
                  }}
                >
                 {portsList?.map((item, index) => (
                    <Option
                      key={index}
                      value={item?.id}
                      disabled={
                        selectedPorts?.length === 5 &&
                        !selectedPorts
                          ?.map((portId) => portId)
                          ?.includes(item?.id)
                      }
                    >
                      {`${item?.port}(${item?.code})`}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            )}
            <FormItem
              name="agreedToTerms"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (agreedToTerms) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Please agree to the Terms and Conditions."
                      );
                    }
                  },
                },
              ]}
            >
              <Checkbox
                checked={agreedToTerms}
                onChange={(e) => setAgreedToTerms(e.target.checked)}
              >
                I agree to my exim business&nbsp;
                <a href="/terms-of-service" target="_blank">
                  Terms of Service
                </a>
                &nbsp;and&nbsp;
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
              </Checkbox>
            </FormItem>
            <FormItem
              className="gx-text-center"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                block
                loading={loading}
              >
                Register Account
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SignUp;

// actions/freightRateAction.js
import { getFreightRates } from "../services/updatefreightrateNetwork";
import { GET_FREIGHT_RATES_LIST_SUCCESS } from "../types/commonTypes";

export const getFreightRatesList = () => async (dispatch) => {
    const response = await getFreightRates();
    dispatch({
      type: GET_FREIGHT_RATES_LIST_SUCCESS,
      payload: response,
    });
  };
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Common from "./Common";
import Auth from "./authReducer";
import MyProducts from "./myProductsReducer";
import RequestQuotation from "./requestQuotationReducer";
import { pointsReducer } from './pointsReducer';
import freightRateReducer  from "./updatefreightrateReducer"


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  common: Common,
  auth: Auth,
  myProducts:MyProducts,
  requestQuotations:RequestQuotation,
  points:pointsReducer,
  freightRates: freightRateReducer,
});

export default createRootReducer;

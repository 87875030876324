// reducers/freightRateReducer.js
import { GET_FREIGHT_RATES_LIST_SUCCESS, UPDATE_FREIGHT_RATE_SUCCESS } from "../types/commonTypes";

const initialState = {
  freightRatesList: [],
};
console.log("freightRatesList",initialState)

 const freightRateReducer=(state = initialState, action)=> {
  switch (action.type) {
    case GET_FREIGHT_RATES_LIST_SUCCESS:
      return {
        ...state,
        freightRatesList: action.payload,
      };
    case UPDATE_FREIGHT_RATE_SUCCESS:
      return {
        ...state,
        freightRatesList: state.freightRatesList.map((rate) =>
          rate.id === action.payload.id ? { ...rate, ...action.payload.data } : rate
        ),
      };
    default:
      return state;
  }
}

export default freightRateReducer;